import Colors from './color';
import { IMenuVersion } from './menu';

export const CLOUDFRONT_BASE_URL = 'https://d3hj7yqiplb18y.cloudfront.net/';
export enum VISIBILITY_FACTOR {
  CART_EMPTY,
  CART_ACTIVE,
}

export interface IntentCard {
  title: string;
  color: string;
  textColor?: string;
  intent: string;
  cardSize: number;
  disabled?: boolean;
  visibility?: VISIBILITY_FACTOR;
  messages: string[];
}

export const INTENT_CARDS: IntentCard[] = [
  {
    title: 'Welcome',
    color: Colors.welcome,
    textColor: Colors.white,
    cardSize: 4,
    intent: '#greet',
    messages: [
      'What can I make fresh for you today?',
      'Hi, What can I make fresh for you today?',
      'Welcome, What can I make fresh for you today?',
      'What can I start you off with today?',
      'Hello! what would you like to order?',
      'Welcome! what would you like to order?',
    ],
  },
  {
    title: 'Okay',
    color: Colors.white,
    intent: '#acknowledge',
    cardSize: 4,
    messages: ['Sure', 'Got it.', 'Okay, got it.', "Okay, I've got it"],
  },
  {
    title: 'Say Again',
    color: Colors.sayAgain,
    textColor: Colors.white,
    intent: '#ask-repeat',
    cardSize: 4,
    messages: [
      'Can you repeat that?',
      'Could you say that again?',
      'Sorry, could you please repeat that one more time?',
      "Sorry, I didn't catch that. Could you repeat that one more time?",
    ],
  },
  {
    title: 'Moment',
    color: Colors.white,
    intent: '#ask-moment',
    cardSize: 4,
    messages: [
      'One moment.',
      'One moment please.',
      'One moment please, let me confirm that.',
    ],
  },
  {
    title: 'Restaurant Staff',
    color: Colors.torchRed,
    textColor: Colors.white,
    intent: '#request-staff',
    disabled: true,
    cardSize: 4,
    messages: [
      'One moment please, let me get someone to help you',
      'One moment, let me get a team member to help you',
      'Hang on while I get someone to help you',
      'One quick minute, let me grab someone to help',
    ],
  },
  {
    title: 'Continue',
    color: Colors.white,
    intent: '#continue',
    cardSize: 4,
    messages: [
      'Is that all?',
      'Does that complete your order?',
      'What else can I get for you?',
    ],
  },
  {
    title: 'Read Back',
    color: Colors.readBack,
    textColor: Colors.white,
    intent: '#readback',
    cardSize: 4,
    messages: [],
  },
  {
    title: 'Complete',
    color: Colors.white,
    intent: '#window',
    cardSize: 4,
    messages: [
      '{TOTAL}, Please pull forward!',
      '{TOTAL}, Please drive forward!',
      '{TOTAL}, Please pull up to the window!',
      'Okay, Please pull up to the next window.',
    ],
  },
  {
    title: 'Total',
    color: Colors.total,
    textColor: Colors.white,
    intent: '#read-total',
    cardSize: 4,
    messages: [
      '{TOTAL}',
      'Total is {TOTAL}',
      'That comes to {TOTAL}',
      '{TOTAL} Please pull forward!',
    ],
  },
];

export const HYPOTHESE_MINI_CONFIDENCE = 0;

export enum END_SESSION_REASON {
  CANCEL_ORDER = 'cancel_order',
  FINISH_ORDER = 'finish_order',
}

export const POS_BOOLEAN_PROPERTIES_LIST = [
  'is_combo',
  'is_modifier',
  'is_coupon',
  'is_main_item',
];

export const PROD_ACCESS_DETAILS = {
  'prod-access-key': 'd3b9116eaaa8022368e21e2a1c96a49a',
};
export const DUMMY_MOD_SYMBOL = '.';

export const OH = 'Oh';
export const LOWER_CASE_O = 'o';
export const VOICE_PROPERTIES = 'voice_properties';
export const SOURCE = 'HITL';

export const RESTAURANT_DIAGNOSTIC_USERNAME = 'restaurant-diagnostic';
export const RESTAURANT_DIAGNOSTIC_PASSWORD = 'diagnostic@presto';

export const TIME_PERIOD_CHECK_INTERVAL = 30 * 1000;

export const defaultMenuVersion = { commitId: 'latest' } as IMenuVersion;
export const GROUP_ID_MAPPING_DEFAULT_RESTAURANT = 'cke_corp_reduced';

// sample total message '4 dollars 2 cents'
export const BRANDS_REQUIRE_LONG_MESSAGE_WITH_TOTAL = ['fazolis'];

export const TTS_ON_COMMAND = '//ttson';
export const TTS_OFF_COMMAND = '/\\ttsoff'; // The backslash ("\") character is a special escape character. To use backslash character itself, two backslashes are needed.
