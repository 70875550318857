import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Header from './components/Layout/Header';
import LoadingIndicator from './components/LoadingIndicator';
import { IDLE_TIME } from './constants';
import useSessionStatus from './hooks/sessionStatus/useSessionStatus.hooks';
import useTaskRouterWS from './hooks/taskRouterWS/useTaskRouterWS.hooks';
import useUnleashHooks from './hooks/unleash/useUnleash.hooks';
import useAppIdleTimer from './hooks/useAppIdleTimer.hooks';
import useExternalOrderHandler from './hooks/useExternalOrderHandler.hooks';
import { refreshAuthToken } from './reducers/userSlice';
import {
  selectIsFetchingTaskStatus,
  selectIsLoading,
} from './redux/features/loading/loading.selector';
import { selectCurrentTaskAIStatus } from './redux/features/taskRouter/taskRouter.selector';
import { selectActiveRestaurantDetails } from './selectors/restaurant';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  appClass: {
    height: '100vh',
  },
  containerClass: {
    height: `calc(100% - ${spacing(8)})`,
  },
}));

function App() {
  const { appClass, containerClass } = useStyles();

  useAppIdleTimer();
  useSessionStatus();
  useExternalOrderHandler();
  useUnleashHooks();
  const {
    handleAgentAvailability,
    sendTaskMessage,
    sendAgentEvent,
    initializeTaskRouter,
    isTaskAssignmentMode,
  } = useTaskRouterWS();
  const isLoading = useAppSelector(selectIsLoading);
  const isFetchingTaskStatus = useAppSelector(selectIsFetchingTaskStatus);
  const currentTaskAIStatus = useAppSelector(selectCurrentTaskAIStatus);
  const { restaurantName } = useAppSelector(selectActiveRestaurantDetails);
  const dispatch = useAppDispatch();

  //NOTE: This is a temporary fix. Current JWT token expiry is 60 minutes, refreshing JWT token every 30 minutes so that 401 errors doesn't occur
  useEffect(() => {
    let refreshTimer = setInterval(
      () => dispatch(refreshAuthToken()),
      IDLE_TIME / 2
    );
    return () => {
      clearInterval(refreshTimer);
    };
  }, []);

  return (
    <div data-testid="app" className={appClass}>
      <Header
        handleAgentAvailability={handleAgentAvailability}
        sendAgentEvent={sendAgentEvent}
      />
      <div data-testid="app-detail" className={containerClass}>
        {isLoading ? (
          <LoadingIndicator
            restaurantName={restaurantName}
            isFetchingTaskStatus={isFetchingTaskStatus}
            aiStatus={currentTaskAIStatus}
          />
        ) : (
          <Outlet
            context={{
              handleAgentAvailability,
              sendTaskMessage,
              initializeTaskRouter,
              isTaskAssignmentMode,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default App;
