import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTH_TOKEN_RESPONSE_HEADER } from '../constants';
import { GroupIdMappingQuery } from '../generated-interfaces/graphql';
import { resetAI } from '../redux/features/ai/ai.slice';
import { UserDetails } from '../types';
import { get, post } from '../utils/api';
import { GROUP_ID_MAPPING_DEFAULT_RESTAURANT } from '../utils/constants';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import { getAuthToken, saveUserState } from '../utils/local-storage';
import logger from '../utils/logger';
import { getGraphQLClient, setAuthToken } from '../utils/network';
import { PerfTimer } from '../utils/timer';
import { ERROR_ACTIONS } from './errorSlice';
import {
  resetHypothesisFrame,
  sendNetworkCallLogs,
  setIsAIActive,
} from './messagingSlice';
import { restaurantsByUserRole } from './restaurantSlice';

export interface UserState {
  userProfile: UserDetails | null;
  isLoggedIn: boolean;
  token: string | null;
  didAttemptAuthCheck: boolean;
}

export const initialUserState: UserState = {
  userProfile: null,
  isLoggedIn: false,
  token: null,
  didAttemptAuthCheck: false,
};

export const login = createAsyncThunk(
  'user/login',
  async (
    { email, password }: { email: string; password: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      config: { AUTH_URL },
    } = getState();
    try {
      const response = await post({
        url: `${AUTH_URL}/login`,
        data: {
          email: email.trim(),
          password,
        },
      });
      const { data, headers = {} } = response || {};

      logger.log('Setting auth token after login API success response');
      setAuthToken(headers[AUTH_TOKEN_RESPONSE_HEADER]);

      dispatch(restaurantsByUserRole());
      dispatch(ERROR_ACTIONS.clearErrors());
      dispatch(setIsAIActive(false));
      dispatch(resetHypothesisFrame());
      dispatch(resetAI());
      return data;
    } catch (error: any) {
      logger.error({
        message: 'GraphQL error: Failed to login',
        error: error.response.errors,
      });
      return rejectWithValue(error.response.errors);
    }
  }
);

export const initialAuthCheck = createAsyncThunk(
  'user/initialAuthCheck',
  async (val: boolean, { dispatch, getState, rejectWithValue }) => {
    const {
      config: { AUTH_URL },
    } = getState();
    const authToken = getAuthToken();
    logger.log('Setting auth token in initialAuthCheck');
    if (!authToken) {
      logger.error('No Auth Token Found');
      return rejectWithValue(new Error('No Auth Token Found'));
    }
    logger.log('Setting auth token in initialAuthCheck before API call');
    setAuthToken(authToken);
    try {
      const response = await get({
        url: `${AUTH_URL}/login/status`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const { data, headers = {} } = response || {};
      logger.log(
        'Setting auth token in initialAuthCheck after API call success response'
      );
      setAuthToken(headers[AUTH_TOKEN_RESPONSE_HEADER]);
      dispatch(restaurantsByUserRole());
      dispatch(fetchGroupIdMapping(GROUP_ID_MAPPING_DEFAULT_RESTAURANT));
      return data;
    } catch (error) {
      logger.error({
        message: 'GraphQL error: Failed to perform initialAuthCheck',
        error,
      });
      return rejectWithValue(error);
    }
  }
);

/**
 * This is called every 30 minutes so that new JWT token is received and in the apiCallWrapper the new JWT value is stored
 */
export const refreshAuthToken = createAsyncThunk(
  'user/refreshAuthToken',
  async (_, { getState, rejectWithValue }) => {
    const {
      config: { AUTH_URL },
      restaurant: {
        selectedRestaurantDetails: { restaurantCode },
      },
    } = getState();
    logger.log('Refreshing auth token');
    const authToken = getAuthToken();
    if (!authToken) {
      logger.info('No Auth Token Found');
      return rejectWithValue(new Error('No Auth Token Found'));
    }
    let url = `${AUTH_URL}/login/status?`;
    if (restaurantCode) {
      url += `restaurant_code=${restaurantCode}`;
    }
    logger.log('Refreshing auth token before refreshAuthToken API call');
    try {
      const response = await get({
        url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { headers = {} } = response || {};
      logger.log('Setting auth token after refreshAuthToken API call response');
      setAuthToken(headers[AUTH_TOKEN_RESPONSE_HEADER]);

      // return authStatus;
    } catch (error) {
      logger.error({
        message: 'Failed to get new auth token',
        error,
      });
      return rejectWithValue(error);
    }
  }
);

export const fetchGroupIdMapping = createAsyncThunk(
  'user/fetchGroupIdMapping',
  async (restaurantCode: string, { dispatch, rejectWithValue, getState }) => {
    const {
      config: { PRP_API },
    } = getState();
    const sdk = getGraphQLClient(PRP_API);
    const requestTimer = new PerfTimer();
    try {
      const data =
        ((await sdk.groupIdMapping({
          // hard-coding the restaurant code as we have only one restaurant with this requirement.
          restaurantCode,
        })) as any as GroupIdMappingQuery) || {};
      if (data) {
        const { queryCKEMenu = '{}' } = data;
        inMemoryGroupIdMapping.setGroupIdMappingList(
          restaurantCode,
          queryCKEMenu ? JSON.parse(queryCKEMenu) : {}
        );
      }

      dispatch(
        sendNetworkCallLogs({
          url: PRP_API,
          graphQLOperation: 'groupIdMapping',
          duration: requestTimer.stop(),
          message: 'api request succeeded',
          via: 'graphql_sdk',
        })
      );
    } catch (error: any) {
      logger.error({
        message: 'GraphQL error: Failed to get group id mapping',
        error: error?.response?.errors,
      });

      dispatch(
        sendNetworkCallLogs({
          url: PRP_API,
          graphQLOperation: 'groupIdMapping',
          duration: requestTimer.stop(),
          message: 'api request failed',
          via: 'graphql_sdk',
        })
      );

      return rejectWithValue(error?.response?.errors);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    logout: (state) => {
      state.userProfile = null;
      state.isLoggedIn = false;
      state.token = null;
      logger.log('Setting auth token during logout');
      setAuthToken(null);
      saveUserState(state);
      logger.clearLogs();
    },
    authCheckSuccess: (state, action) => {
      if (state.userProfile) {
        state.token = action.payload.authorizationToken;
        state.userProfile.authorizationToken =
          action.payload.authorizationToken;
        saveUserState(state);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.userProfile = {
        ...action.payload,
        id: action.payload.id + '',
      };
      state.isLoggedIn = true;
      state.didAttemptAuthCheck = true;
      saveUserState(state);
      logger.clearLogs();
    });
    builder.addCase(initialAuthCheck.fulfilled, (state, action) => {
      state.userProfile = {
        ...action.payload,
        id: action.payload.id + '',
      };
      state.token = action.payload.authorizationToken;
      state.isLoggedIn = true;
      state.didAttemptAuthCheck = true;
      saveUserState(state);
    });
    builder.addCase(initialAuthCheck.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.didAttemptAuthCheck = true;
    });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
