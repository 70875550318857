import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import humps from 'lodash-humps-ts';
import { MenuStages } from '../constants/enums';
import { EventTypes } from '../constants/event';
import { Currency, Maybe } from '../generated-interfaces/graphql';
import {
  getSessionBufferMessages,
  IMessageReceived,
  messagingActions,
  resetHypothesisFrame,
  setIsAIActive,
} from '../reducers/messagingSlice';
import { AIStatusColors } from '../redux/features/ai/ai.constants';
import {
  resetAI,
  setAIStatus,
  setIsAIAutoMode,
  setIsAIEscalation,
  toggleReasonsDialog,
} from '../redux/features/ai/ai.slice';
import {
  startLoading,
  stopLoading,
} from '../redux/features/loading/loading.slice';
import { RolePermissions } from '../types/restaurant';
import { readEnvVariable } from '../utils';
import { get } from '../utils/api';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import { getAuthToken } from '../utils/local-storage';
import logger from '../utils/logger';
import {
  getRestaurantAccessLevels,
  getSelectedRestaurantAccess,
  hasRoleAccess,
  RestaurantAccess,
} from '../utils/restaurants';
import {
  fetchMenu,
  fetchMenuVersions,
  resetVersions,
  updateSelectedMenuVersion,
} from './menuSlice';
import { fetchGroupIdMapping, refreshAuthToken } from './userSlice';

export interface IRestaurantInfo {
  restaurantName: string;
  restaurantCode: string;
  restaurantBranding: Maybe<RestaurantBranding>;
}

export interface RestaurantState {
  selectedRestaurantDetails: {
    restaurantCode: string;
    primaryRestaurantCode: string;
    restaurantName: string;
    timezone: string;
    isUnsupervisedAi: boolean;
    restaurantSettings: {
      toGoModalityTaxRate: number;
      deliveryModalityTaxRate: number;
      dineInModalityTaxRate: number;
    };
  };
  restaurantsByUserRole: UserRestaurantsRole[];
  restaurantAccessLevels: RestaurantAccess;
  userRolesHaveLoaded: boolean;
  restaurantsById: Record<string, IRestaurantInfo>;
  selectedStage: MenuStages;
  isAIEnabled: Boolean;
}

export const initialState: RestaurantState = {
  selectedRestaurantDetails: {
    restaurantCode: '',
    primaryRestaurantCode: '',
    restaurantName: '',
    timezone: '',
    isUnsupervisedAi: false,
    restaurantSettings: {
      toGoModalityTaxRate: 0,
      deliveryModalityTaxRate: 0,
      dineInModalityTaxRate: 0,
    },
  },
  restaurantsByUserRole: [],
  restaurantAccessLevels: {},
  userRolesHaveLoaded: false,
  restaurantsById: {},
  selectedStage: MenuStages.LIVE,
  isAIEnabled: false, //Value indicating whether a restaurant is AI enabled (or) not
};

export type UserRestaurantsRole = {
  restaurants: Array<IRestaurantInfo>;
  role: RolePermissions;
};

export type HoursOfOperation = {
  availability?: Array<Availability>;
  timezone: string;
  currentUTCTime: string;
};

export type Availability = {
  alwaysEnabled: boolean;
  day: number;
  hours?: Array<Array<number>>;
};

export enum RestaurantAccountType {
  Demo = 'DEMO',
  Live = 'LIVE',
}

export type RestaurantBranding = {
  backgroundImageUrl: string;
  restaurantLogoUrl: string;
};

export type Restaurant = {
  accountType: RestaurantAccountType;
  hoursOfOperation: HoursOfOperation;
  primaryRestaurantCode: string;
  restaurantBranding: RestaurantBranding;
  restaurantCode: string;
  restaurantContactInfo: RestaurantContactInfo;
  restaurantName: string;
  restaurantSettings: RestaurantSettings;
};

export type RestaurantContactInfo = {
  address?: string;
  city?: string;
  country?: string;
  email?: string;
  phone_number?: string;
  state?: string;
  website?: string;
  zip_code?: string;
};

export type RestaurantSettings = {
  currency?: Currency;
  deliveryModalityTaxRate: number;
  dineInModalityTaxRate: number;
  disablePortalMenu?: boolean;
  isAlertingEnabled?: boolean;
  toGoDefaultSmsText?: string;
  toGoModalityTaxRate: number;
  toGoOrderConfirmationMessage?: string;
  toGoPickupMinutes?: number;
  isUnsupervisedAi?: boolean;
};

export const restaurantsByUserRole = createAsyncThunk(
  'restaurant/restaurantsByUserRole',
  async (_, { getState }) => {
    const {
      config: { RESTAURANT_SERVICE_URL },
    } = getState();

    const authToken = getAuthToken();
    if (!authToken) {
      logger.error(
        'No Auth Token Found before calling restaurant service with data=roles'
      );
    }

    const response = await get({
      url: `${RESTAURANT_SERVICE_URL}/restaurants?data=roles`,
      headers: {
        Authorization: getAuthToken(),
      },
    });
    return humps(response?.data);
  }
);

export const selectStage = createAsyncThunk(
  'restaurant/selectStage',
  async (_, { getState, dispatch }) => {
    const {
      config: { FEATURE_FLAG_AUDIO_BACKEND },
      restaurant: {
        selectedRestaurantDetails: { timezone, restaurantCode },
        selectedStage,
      },
    } = getState();
    const { PLAYGROUND, LIVE } = MenuStages;
    const isPlayground = selectedStage === PLAYGROUND;
    const currentStage = isPlayground ? LIVE : PLAYGROUND;
    const currentMenuVersion = isPlayground ? '' : 'latest';

    dispatch(updateSelectedStage(currentStage));
    dispatch(updateSelectedMenuVersion(currentMenuVersion));

    if (restaurantCode) {
      dispatch(startLoading({}));
      dispatch(
        fetchMenu({
          currentMenuVersion,
          timezone,
          restaurantCode,
          currentStage,
        })
      ).then((_) => {
        dispatch(messagingActions.clearMessages());
        dispatch(messagingActions.startConnecting());
        if (FEATURE_FLAG_AUDIO_BACKEND)
          dispatch(messagingActions.startAudioWSConnecting());
      });
    }
    return currentStage;
  }
);

export const selectRestaurant = createAsyncThunk(
  'restaurant/selectRestaurant',
  async (
    {
      restaurantCode,
      taskRouterMenuLoadFailureCallback,
      taskRouterMenuLoadSuccessCallback,
    }: {
      restaurantCode: string;
      taskRouterMenuLoadFailureCallback?: Function;
      taskRouterMenuLoadSuccessCallback?: Function;
    },
    { getState, dispatch, rejectWithValue }
  ) => {
    const {
      config: {
        FEATURE_FLAG_MENU_VERSION,
        RESTAURANT_SERVICE_URL,
        FEATURE_FLAG_AUDIO_BACKEND,
      },
      restaurant: { restaurantAccessLevels },
      taskRouter: { currentTaskId, tasksById },
    } = getState();

    let timezone = '',
      restaurantName = '',
      primaryRestaurantCode = '',
      isUnsupervisedAi = false,
      currentTaskAIStatus = null,
      restaurantSettings = {
        toGoModalityTaxRate: 0,
        deliveryModalityTaxRate: 0,
        dineInModalityTaxRate: 0,
      };

    const currentStage = MenuStages.LIVE;
    const currentMenuVersion = '';

    dispatch(messagingActions.closeConnection());
    dispatch(messagingActions.closeAudioWSConnection()); // As FEATURE_FLAG_AUDIO_BACKEND in Unleash can change anytime, we should always close the audio WS connection
    dispatch(resetVersions());
    dispatch(updateSelectedRestaurantCode(restaurantCode));
    dispatch(updateSelectedMenuVersion(currentMenuVersion));
    dispatch(updateSelectedStage(currentStage));
    dispatch(messagingActions.clearMessages());
    dispatch(messagingActions.startConnecting());
    dispatch(resetHypothesisFrame());
    if (FEATURE_FLAG_AUDIO_BACKEND)
      dispatch(messagingActions.startAudioWSConnecting());

    if (currentTaskId) {
      //HITL operated via Task router mode

      const {
        restaurant_name,
        timezone: restaurantTimeZone,
        is_unsupervised_ai,
        ai_enabled = false,
      } = tasksById[currentTaskId].data.restaurant_data || {};
      currentTaskAIStatus = tasksById[currentTaskId].data?.ai_status;

      restaurantName = restaurant_name;
      timezone = restaurantTimeZone;
      isUnsupervisedAi = is_unsupervised_ai;

      restaurantSettings = {
        toGoModalityTaxRate: 0,
        deliveryModalityTaxRate: 0,
        dineInModalityTaxRate: 0,
      };

      dispatch(updateAIEnabledStatus(ai_enabled));
      dispatch(setIsAIActive(ai_enabled));

      if (currentTaskAIStatus) {
        dispatch(setIsAIAutoMode(true));
        dispatch(toggleReasonsDialog(false));
        dispatch(setAIStatus(currentTaskAIStatus));
        if (currentTaskAIStatus?.status === AIStatusColors.red) {
          dispatch(setIsAIEscalation(true));
        }
      }

      dispatch(
        getSessionBufferMessages({
          isCurrentTaskUnsupervised: !!currentTaskAIStatus,
        })
      );
    } else {
      //HITL operated via default mode: Agent selecting a restaurant via dropdown
      const authToken = getAuthToken();
      if (!authToken) {
        logger.error(
          'No auth token found for fetching restaurant service data=info API call'
        );
        return rejectWithValue(new Error('No Auth Token Found'));
      }
      const response = (
        await get({
          url: `${RESTAURANT_SERVICE_URL}/restaurants/${restaurantCode}?data=info`,
          headers: {
            Authorization: getAuthToken(),
          },
          errorCallback: () => {
            dispatch(stopLoading());
          },
        })
      )?.data;

      const { restaurant_name, hours_of_operation, restaurant_settings } =
        response || {};

      restaurantName = restaurant_name;
      primaryRestaurantCode = '';
      timezone = hours_of_operation?.timezone;

      isUnsupervisedAi = restaurant_settings?.is_unsupervised_ai;
      restaurantSettings = {
        toGoModalityTaxRate: restaurant_settings?.to_go_modality_tax_rate,
        deliveryModalityTaxRate:
          restaurant_settings?.delivery_modality_tax_rate,
        dineInModalityTaxRate: restaurant_settings?.dine_in_modality_tax_rate,
      };

      const selectedRestaurantAccessLevel = getSelectedRestaurantAccess(
        restaurantCode,
        restaurantAccessLevels
      );
      const hasMenuVersionAccess = hasRoleAccess(
        RolePermissions.RestaurantManager,
        selectedRestaurantAccessLevel
      );

      await dispatch(refreshAuthToken());

      if (FEATURE_FLAG_MENU_VERSION && hasMenuVersionAccess) {
        dispatch(
          fetchMenuVersions({
            restaurantCode,
          })
        );
      }

      await dispatch(
        getAIControlPanelStatus({ restaurantCode, timeout: 2000 })
      );
    }

    const groupIdMappingsList = inMemoryGroupIdMapping.getGroupIdMappingList();
    if (!groupIdMappingsList[restaurantCode])
      dispatch(fetchGroupIdMapping(restaurantCode));

    dispatch(
      fetchMenu({
        restaurantCode,
        timezone,
        currentMenuVersion,
        currentStage,
        ...(currentTaskId
          ? {
              taskRouterMenuLoadFailureCallback,
              taskRouterMenuLoadSuccessCallback,
            }
          : {}),
      })
    );

    if (!currentTaskAIStatus) {
      dispatch(resetAI());
    }

    return {
      restaurantCode,
      primaryRestaurantCode,
      restaurantName,
      timezone,
      isUnsupervisedAi,
      restaurantSettings,
    };
  }
);

export const getAIControlPanelStatus = createAsyncThunk(
  'restaurant/getAIControlPanelStatus',
  async (
    {
      restaurantCode,
      timeout = 60000,
    }: { restaurantCode: string; timeout?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      config: { AI_API },
    } = getState();

    dispatch(updateAIEnabledStatus(false)); //To improve user experience, initializing the value to false
    dispatch(setIsAIActive(false));

    await Promise.race([
      get({
        url: `${AI_API}/v1/controlpanel/${restaurantCode}/voice`,
        headers: {
          Authorization: `Bearer ${readEnvVariable('AI_AUTH')}`,
        },
        successCallback: (response) => {
          const { ai_enabled } = response.data;
          if (ai_enabled) {
            dispatch(updateAIEnabledStatus(ai_enabled));
            dispatch(setIsAIActive(ai_enabled));
          }
        },
        errorCallback: (error) => {
          logger.error('Failed to get AI connector status', error);
          return rejectWithValue(error);
        },
      }),
      new Promise((resolve, reject) => {
        setTimeout(
          resolve,
          timeout,
          new Error('Timeout to get response from Control Panel API')
        );
      }),
    ]).then((res) => {
      if (res instanceof Error) {
        logger.error(res);
      }
    });
  }
);

export const restaurantState = createSlice({
  name: 'restaurant',
  initialState: initialState,
  reducers: {
    updateSelectedStage: (state, { payload }: PayloadAction<MenuStages>) => {
      state.selectedStage = payload;
    },
    resetSelectedRestaurantDetails: (state) => {
      state.selectedRestaurantDetails = {
        restaurantCode: '',
        primaryRestaurantCode: '',
        restaurantName: '',
        timezone: '',
        isUnsupervisedAi: false,
        restaurantSettings: {
          toGoModalityTaxRate: 0,
          deliveryModalityTaxRate: 0,
          dineInModalityTaxRate: 0,
        },
      };
    },
    updateSelectedRestaurantCode: (state, { payload }) => {
      state.selectedRestaurantDetails.restaurantCode = payload;
    },
    updateAIEnabledStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isAIEnabled = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectRestaurant.fulfilled, (state, action) => {
      state.selectedRestaurantDetails = action.payload;
    });
    builder.addCase(restaurantsByUserRole.fulfilled, (state, action) => {
      state.restaurantsByUserRole = action.payload;
      state.restaurantAccessLevels = getRestaurantAccessLevels(action.payload);
      state.userRolesHaveLoaded = true;

      const acc: Record<string, IRestaurantInfo> = {};
      state.restaurantsByUserRole.reduce((a, c) => {
        c.restaurants.forEach((r) => {
          if (!(r.restaurantCode in a)) {
            a[r.restaurantCode] = r;
          }
        });
        return a;
      }, acc);
      state.restaurantsById = acc;
    });
    builder.addCase(restaurantsByUserRole.rejected, (state, action) => {
      state.userRolesHaveLoaded = true;
      state.restaurantsById = {};
    });
    builder.addCase(selectStage.fulfilled, (state, action) => {
      state.selectedStage = action.payload;
    });
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<IMessageReceived>) => {
        const { messages } = action.payload;
        messages.forEach((message) => {
          if (message.event === EventTypes.aiStatus) {
            if (!state.isAIEnabled) state.isAIEnabled = true;
          }
        });
      }
    );
  },
});

export const {
  updateSelectedStage,
  resetSelectedRestaurantDetails,
  updateSelectedRestaurantCode,
  updateAIEnabledStatus,
} = restaurantState.actions;

export default restaurantState.reducer;
