import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '../components/Layout/Image';
import { Routes } from '../constants/enums';
import prestoLogo from '../images/presto_logo_white.png';
import Colors from '../utils/color';
import logger from '../utils/logger';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  unauthorizedPageClass: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.daintree,
    color: Colors.white,
  },
  prestoLogoClass: {
    marginBottom: spacing(4),
    '@media (max-height: 600px)': {
      marginBottom: 0,
    },
  },
  actionButtonClass: {
    marginTop: `${spacing(2)} !important`,
    textTransform: 'uppercase',
  },
}));

const UnauthorizedPage = () => {
  const { unauthorizedPageClass, prestoLogoClass, actionButtonClass } =
    useStyles();
  const navigate = useNavigate();
  const onButtonClick = () => {
    logger.info({
      message: 'In UnauthorizedPage: Before navigating to task-router page',
      isTR: true,
    });
    navigate(`/${Routes.taskRouter}`);
  };

  return (
    <div data-testid="unauthorized-page" className={unauthorizedPageClass}>
      <Image className={prestoLogoClass} url={prestoLogo} alt="Presto Logo" />
      <Typography variant="h4" gutterBottom>
        You're unauthorized to access this
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={onButtonClick}
        className={actionButtonClass}
      >
        Go to Home page
      </Button>
    </div>
  );
};

export default UnauthorizedPage;
