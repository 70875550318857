import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuStages } from '../constants/enums';
import { CartItemAdditionTypes } from '../constants/event';
import {
  Category,
  CategoryAndTimePeriodQueryQuery,
  MenuItem,
  MenuItemSetting,
  MenuItemsQueryQuery,
  MenuOverride,
  ModalityType,
  ModifierGroup,
  ModifierGroupsQueryQuery,
  PosProperties,
  TimePeriod,
} from '../generated-interfaces/graphql';
import { startLoading } from '../redux/features/loading/loading.slice';
import { IUnavailableItem } from '../types';
import { defaultMenuVersion } from '../utils/constants';
import {
  getModifierAndModGroupFromHypotheses,
  getModifierAndModGroupFromHypothesesByName,
} from '../utils/hypotheses';
import logger from '../utils/logger';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import {
  buildFullMenuItem,
  considerTimePeriodCategory,
  fetchMenuBasedOnStage,
  findCartItemInMenuGraphByName,
  formatCategoryAndTimePeriodResponse,
  formatMenuResponse,
  formatModGroupResponse,
  getComboPrpIdToALaCartePrpId,
  IMenuVersion,
  IMenuVersionsResponse,
  MenuResponses,
  parseCategoryAndTimeperiodResponse,
  ParsedCategory,
  ParsedMenuItem,
  parseMenuResponse,
  PersistentMenuProperty,
  TopLevelMenuItem,
  getEligibleAutoComboItem,
} from '../utils/menu';
import { getMenuVersionsFromMenuAPI } from '../utils/network';
import { GenericMap } from '../utils/types';
import {
  addDummyHypothesisItemToCart,
  cartActions,
  setSequenceId,
} from './cartSlice';
import { dialogActions } from './dialogSlice';
import {
  EntityMenuItem,
  ErrorTransmissionMessage,
  messagingActions,
} from './messagingSlice';
import {
  CartItem,
  selectModifierInCartItem,
  getCartItemNonDummyNode,
  getValidCartItems,
} from '../utils/cart';
import {
  resetAutoComboState,
  setAutoCombinedCart,
} from '../redux/features/autoCombo/autoCombo.slice';
import { sendOrder } from './orderSlice';

export interface MenuState {
  topLevelMenuItems: GenericMap<TopLevelMenuItem>;
  availableCategoryWithTimePeriod: ParsedCategory[];
  categoriesWithTimePeriod: ParsedCategory[];
  alwaysAvailableCategories: ParsedCategory[];
  menuRes?: MenuResponses;
  fullMenuItems: GenericMap<ParsedMenuItem>;
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  modSymbolMapping: ModSymbolCodeNameMappingType;
  codeNameMapping: ModSymbolCodeNameMappingType;
  menuVersions: IMenuVersion[];
  selectedMenuVersion: string;
  prodLiveVersion?: IMenuVersion;
  unavailableItems: Record<string, IUnavailableItem>;
  autoComboPrpIds: string[];
  comboPrpIdToALaCartePrpId: Record<string, string>;
}

export interface IAddItemToCart {
  menuItem: TopLevelMenuItem;
  quantity?: number;
  addedBy?: CartItemAdditionTypes;
  prefixWord?: string;
  inputModSymbol?: string;
}
export interface IComboItemMatch {
  autoComboPrpId: string;
  modifierGroupId: string;
  modifierPrpId: string;
  cartItemId: number;
  nonDummyItemPrpId?: string;
  nonDummyCartItemPrpId?: number;
}

export interface IProcessedCategoryAndTimePeriodJSON {
  categories: GenericMap<Category>;
  timePeriods: GenericMap<TimePeriod>;
}

export interface IProcessedMenuJSON {
  menuItems: GenericMap<MenuItem>;
  overrides: GenericMap<MenuOverride>;
  menuItemSettings: GenericMap<MenuItemSetting>;
  posSettings: GenericMap<PosProperties>;
}

export interface IProcessedModGroupsJSON {
  modifierGroups: GenericMap<ModifierGroup>;
}
export interface IUpdateMenuItems {
  topLevelMenuItems: GenericMap<TopLevelMenuItem>;
  processedCategoryAndTimePeriodJSON: GenericMap<IProcessedCategoryAndTimePeriodJSON>;
  processedMenuJSON: GenericMap<IProcessedMenuJSON>;
  processedModGroupsJSON: GenericMap<IProcessedModGroupsJSON>;
  categoriesWithTimePeriod: ParsedCategory[];
  availableCategoryWithTimePeriod: ParsedCategory[];
  alwaysAvailableCategories: ParsedCategory[];
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  codeNameMapping: ModSymbolCodeNameMappingType;
  modSymbolMapping: ModSymbolCodeNameMappingType;
  unavailableItems: Record<string, IUnavailableItem>;
}

export const initialState: MenuState = {
  topLevelMenuItems: {},
  availableCategoryWithTimePeriod: [],
  categoriesWithTimePeriod: [],
  alwaysAvailableCategories: [],
  menuRes: undefined,
  fullMenuItems: {},
  persistentVoiceProps: {},
  modSymbolMapping: {},
  codeNameMapping: {},
  menuVersions: [],
  selectedMenuVersion: 'latest',
  prodLiveVersion: {} as IMenuVersion,
  unavailableItems: {},
  autoComboPrpIds: [],
  comboPrpIdToALaCartePrpId: {},
};

/**
 * This function takes the menu data returned from APIs and make it in usable fashion to be stored in redux state
 */
const processMenuResponse = ({
  categoryAndTimePeriodJSON,
  menuJSON,
  modifierGroupJSON,
  persistentMenuProperty,
  unavailableItems,
  timezone,
  currentStage,
  getState,
}: {
  categoryAndTimePeriodJSON: CategoryAndTimePeriodQueryQuery | {};
  menuJSON: MenuItemsQueryQuery | {};
  modifierGroupJSON: ModifierGroupsQueryQuery | {};
  persistentMenuProperty: PersistentMenuProperty[];
  unavailableItems: Record<string, IUnavailableItem>;
  timezone: string;
  currentStage: string;
  getState: Function;
}) => {
  const {
    restaurant: { selectedRestaurantCode } = { selectedRestaurantCode: '' },
  } = getState();
  let persistentVoiceProps = {};
  try {
    if (persistentMenuProperty?.length > 0) {
      Object.assign(
        persistentVoiceProps,
        (persistentMenuProperty as PersistentMenuProperty[]).reduce(
          (acc, entry) => {
            acc[entry.unique_identifier] = entry;
            return acc;
          },
          {} as GenericMap<PersistentMenuProperty>
        )
      );
    }
  } catch (error) {
    logger.error({
      restaurantCode: selectedRestaurantCode,
      message: 'Failed while processing persistent menu properties',
      error,
    });
  }

  const processedCategoryAndTimePeriodJSON =
    formatCategoryAndTimePeriodResponse(
      categoryAndTimePeriodJSON as CategoryAndTimePeriodQueryQuery
    );

  const {
    categoriesWithTimePeriod,
    alwaysAvailableCategories,
    autoComboPrpIds,
  } = parseCategoryAndTimeperiodResponse(processedCategoryAndTimePeriodJSON);

  const availableCategoryWithTimePeriod = timezone
    ? considerTimePeriodCategory(categoriesWithTimePeriod, timezone)
    : categoriesWithTimePeriod;

  const processedMenuJSON = formatMenuResponse(menuJSON as MenuItemsQueryQuery);

  const { topLevelMenuItems, codeNameMapping, modSymbolMapping } =
    parseMenuResponse({
      categories: [
        ...availableCategoryWithTimePeriod,
        ...alwaysAvailableCategories,
      ],
      menuRes: processedMenuJSON,
      persistentVoiceProps,
      unavailableItems,
      stage: currentStage,
    });

  const modality = getState().cart.modality;
  const processedModGroupsJSON = formatModGroupResponse(
    modifierGroupJSON as ModifierGroupsQueryQuery
  );

  const comboPrpIdToALaCartePrpId = getComboPrpIdToALaCartePrpId(
    autoComboPrpIds,
    {
      ...processedCategoryAndTimePeriodJSON,
      ...processedMenuJSON,
      ...processedModGroupsJSON,
    }
  );

  return {
    modality,
    topLevelMenuItems,
    processedCategoryAndTimePeriodJSON,
    processedMenuJSON,
    processedModGroupsJSON,
    categoriesWithTimePeriod,
    availableCategoryWithTimePeriod,
    alwaysAvailableCategories,
    persistentVoiceProps,
    codeNameMapping,
    modSymbolMapping,
    unavailableItems,
    autoComboPrpIds,
    comboPrpIdToALaCartePrpId,
  };
};

export const fetchMenu = createAsyncThunk(
  'menu/getMenu',
  async (
    {
      restaurantCode,
      timezone,
      currentMenuVersion,
      currentStage,
      taskRouterMenuLoadFailureCallback,
      taskRouterMenuLoadSuccessCallback,
    }: {
      restaurantCode: string;
      timezone?: string;
      currentMenuVersion: string;
      currentStage: MenuStages;
      taskRouterMenuLoadFailureCallback?: Function;
      taskRouterMenuLoadSuccessCallback?: Function;
    },
    { getState, dispatch }
  ) => {
    try {
      const {
        categoryAndTimePeriodJSON,
        menuJSON,
        modifierGroupJSON,
        persistentMenuProperty,
        unavailableItems,
      } = await fetchMenuBasedOnStage({
        restaurantCode,
        state: getState(),
        currentMenuVersion,
        currentStage,
        dispatch,
      });

      const processedMenu = processMenuResponse({
        categoryAndTimePeriodJSON,
        menuJSON,
        modifierGroupJSON,
        persistentMenuProperty,
        unavailableItems,
        timezone: timezone || '',
        currentStage,
        getState,
      });
      dispatch(updateMenuItems(processedMenu));
      taskRouterMenuLoadSuccessCallback?.();
    } catch (error) {
      const {
        cachedMenu: { menusById },
        taskRouter: { currentTaskId },
        order: { currentSessionId: orderSessionId },
        messages: { currentSessionId: messageSessionId },
      } = getState();

      logger.error({
        restaurantCode,
        message: 'Get Menu Items From Restaurant Portal Failed',
        isTaskFromTaskRouter: !!currentTaskId,
        error: JSON.stringify(error),
      });

      //If fetching the menu of a restaurant fails, as a fallback mechanism the following things are done when task is arrived via task-router
      // - Old menu cache of restaurant exists => use the old version menu
      // - Old menu cache of restaurant does not exists => trigger an intervention with reason code, empty the menu, alert of menu load error(5 seconds), take them to the task router lobby

      if (currentTaskId) {
        //Task arrived via task router
        if (menusById?.[restaurantCode]) {
          //Old menu cache exists for this restaurant
          logger.error({
            restaurantCode,
            message: 'Using old cache of the restaurant',
            isTR: true,
            error,
          });
          const {
            categoryAndTimePeriodJSON,
            menuJSON,
            modifierGroupJSON,
            persistentMenuProperty,
            unavailableItems,
          } = menusById[restaurantCode];
          const processedMenu = processMenuResponse({
            categoryAndTimePeriodJSON,
            menuJSON,
            modifierGroupJSON,
            persistentMenuProperty,
            unavailableItems,
            timezone: timezone || '',
            currentStage,
            getState,
          });
          dispatch(updateMenuItems(processedMenu));
          taskRouterMenuLoadSuccessCallback?.();
        } else {
          //No old cache exists for the restaurant
          logger.error({
            restaurantCode,
            message: 'No old cache exists for the restaurant',
            isTR: true,
            error,
          });
          taskRouterMenuLoadFailureCallback?.({
            restaurantCode,
            orderSessionId,
            messageSessionId,
          });
        }
      }
    }
  }
);

export const formAutoCombos = createAsyncThunk(
  '',
  async (_, { dispatch, getState }) => {
    try {
      const {
        menu: {
          menuRes,
          topLevelMenuItems,
          modSymbolMapping,
          autoComboPrpIds,
          persistentVoiceProps,
        },
        cart: { cartItems, cartItemsQuantity, sequenceId, modality },
        config: { FEATURE_FLAG_AUTO_COMBO },
      } = getState();
      if (!FEATURE_FLAG_AUTO_COMBO) return;
      if (!menuRes) return;

      const validCartItems = Object.values(cartItems);
      // Need at least two valid cart items to create a combo item so stop finding auto combo when there is no valid cart item or the only valid cart item with quantity less than 2
      if (
        !validCartItems.length ||
        (validCartItems.length === 1 &&
          cartItemsQuantity[validCartItems[0].cartItemId] < 2)
      ) {
        dispatch(resetAutoComboState());

        // Trigger sendOrder after resetting auto combo state
        if (validCartItems.length) dispatch(sendOrder({}));
        return;
      }

      const autoCombinedCartItems = Object.assign({}, cartItems);
      const autoCombinedCartItemsQuantity = Object.assign(
        {},
        cartItemsQuantity
      );
      let currSequenceId = sequenceId;

      for (let i = 0; i < autoComboPrpIds.length; i++) {
        // Need at least two valid cart items to create a combo item so stop finding auto combo when there is no valid auto combined cart item or one valid auto combined cart item with quantity less than 2
        const validAutoCombinedCartItems = getValidCartItems(
          autoCombinedCartItems
        );
        const validAutoCombinedCartItemsToArray = Object.values(
          validAutoCombinedCartItems
        );
        if (
          !validAutoCombinedCartItemsToArray.length ||
          (validAutoCombinedCartItemsToArray.length === 1 &&
            autoCombinedCartItemsQuantity[
              validAutoCombinedCartItemsToArray[0].cartItemId
            ] < 2)
        )
          break;

        const autoComboPrpId = autoComboPrpIds[i];
        const cartCopy = Object.assign({}, validAutoCombinedCartItems);
        const cartItemsQuantityCopy = Object.assign(
          {},
          autoCombinedCartItemsQuantity
        );
        const autoCombo = menuRes.menuItems[autoComboPrpId] || {};

        // Check the cart to find the eligible auto combo item
        const {
          isAutoComboEligible,
          comboItemMatches,
          isDummyItem,
          nonDummyItemMatch,
        } = getEligibleAutoComboItem(
          autoCombo,
          menuRes,
          autoComboPrpId,
          cartItemsQuantityCopy,
          cartCopy
        );

        if (isAutoComboEligible && comboItemMatches.length) {
          logger.log(`Find eligible auto combo ${autoCombo.name}`);
          // populate the full menu graph
          const menuItem = topLevelMenuItems[autoComboPrpId];
          if (!menuItem) {
            logger.log(
              `can not find the ${autoCombo.name} with id ${autoComboPrpId} in the menu`
            );
            continue;
          }
          const parsedMenuItem = buildFullMenuItem(
            menuItem,
            menuRes,
            persistentVoiceProps
          );
          const cartItem: CartItem = {
            ...parsedMenuItem,
            cartItemId: currSequenceId,
            childModifierGroups: {},
            modality,
            modcode: menuItem.modcode,
            addedBy: CartItemAdditionTypes.human,
            autoCombinedCartItemIds: [],
          };

          logger.log(`Start building auto combined cart item ${cartItem.name}`);
          // Add the auto combo item to autoCombinedCartItems
          autoCombinedCartItems[currSequenceId] = cartItem;
          autoCombinedCartItemsQuantity[currSequenceId] = 1;

          let node = cartItem;
          if (isDummyItem && nonDummyItemMatch) {
            const nonDummyItemParentModGroup = Object.values(
              parsedMenuItem.modifierGroups
            )[0];
            const nonDummyItem = Object.values(parsedMenuItem.modifierGroups)[0]
              .menuItems[nonDummyItemMatch.id];
            // select the non-dummy item first and use it as the node to populate the items
            logger.log(
              `Select the non dummy item ${nonDummyItem.name} under modifier group ${nonDummyItemParentModGroup.name} in the auto combo ${cartItem.name}`
            );
            selectModifierInCartItem(
              cartItem,
              nonDummyItem,
              nonDummyItemParentModGroup,
              true,
              '',
              modSymbolMapping,
              menuRes.posSettings,
              modality
            );
            node =
              cartItem.childModifierGroups[nonDummyItemParentModGroup.id]
                ?.selectedItems[nonDummyItemMatch.id];
            logger.log(
              `Change auto combined cart item node to ${node.name} for dummy item`
            );
          }

          // Add combo items as modifiers and remove them from the cart
          comboItemMatches.forEach((match) => {
            autoCombinedCartItems[currSequenceId].autoCombinedCartItemIds?.push(
              match.cartItemId
            );

            const comboItemParentModGroup =
              node.modifierGroups[match.modifierGroupId];

            const comboItem =
              comboItemParentModGroup?.menuItems[match.modifierPrpId];
            if (!comboItemParentModGroup || !comboItem) {
              logger.log(
                `Can not find matched combo item ${comboItem?.name} or matched parent modifier group ${comboItemParentModGroup.name} when forming the auto combo`
              );
              return;
            }

            let pathToItem = comboItemParentModGroup.id;
            // Update the cartItem directly
            logger.log(
              `Select the matched combo item ${comboItem.name} under modifier group ${comboItemParentModGroup.name} in ${node.name}`
            );
            selectModifierInCartItem(
              node,
              comboItem,
              comboItemParentModGroup,
              true,
              '',
              modSymbolMapping,
              menuRes.posSettings,
              modality,
              pathToItem
            );

            const handleModifierMatch = (
              cartItemMatch: CartItem,
              comboItem: ParsedMenuItem,
              pathToItem: string
            ) => {
              logger.log(
                'Handle auto combo modifier match: ',
                cartItemMatch.name,
                comboItem.name,
                pathToItem
              );
              const cartItemMatchChildrenModGroupIds = Object.keys(
                cartItemMatch.childModifierGroups
              );
              if (cartItemMatchChildrenModGroupIds.length) {
                const comboItemModGroupIds = Object.keys(
                  comboItem.modifierGroups
                );
                cartItemMatchChildrenModGroupIds.forEach((childModGroupId) => {
                  Object.values(
                    cartItemMatch.childModifierGroups[childModGroupId]
                      .selectedItems
                  ).forEach((cartItemSelectedItem) => {
                    let menuItemMatchId;
                    let parentModGroupMatchId;
                    if (comboItemModGroupIds.includes(childModGroupId)) {
                      // combo item and a la cart item are using the same modifier group
                      menuItemMatchId = cartItemSelectedItem.id;
                      parentModGroupMatchId = childModGroupId;
                    } else {
                      // combo item and a la cart item are not using the same modifier group. Try to match by name.
                      const { menuItemMatchByNameId, modGroupMatchByNameId } =
                        findCartItemInMenuGraphByName(
                          cartItemSelectedItem,
                          comboItem
                        );
                      menuItemMatchId = menuItemMatchByNameId;
                      parentModGroupMatchId = modGroupMatchByNameId;
                    }
                    if (menuItemMatchId && parentModGroupMatchId) {
                      const modGroup =
                        comboItem.modifierGroups[parentModGroupMatchId];
                      const menuItem = modGroup.menuItems[menuItemMatchId];
                      const pathToModifier = [
                        pathToItem,
                        comboItem.id,
                        modGroup.id,
                      ].join('__');
                      logger.log(
                        `Select the matched modifier ${menuItem.name} under modifier group ${modGroup.name} in ${node.name}`
                      );
                      selectModifierInCartItem(
                        node,
                        menuItem,
                        modGroup,
                        true,
                        '',
                        modSymbolMapping,
                        menuRes.posSettings,
                        modality,
                        pathToModifier
                      );
                      handleModifierMatch(
                        cartItemSelectedItem,
                        menuItem,
                        pathToItem
                      );
                    } else {
                      logger.log(
                        `Can not find matched combo item nested modifier ${cartItemSelectedItem?.name} or matched combo item nested modifier group id ${childModGroupId} when forming the auto combo`
                      );
                    }
                  });
                });
              }
            };

            if (autoCombinedCartItems[match.cartItemId]) {
              handleModifierMatch(
                getCartItemNonDummyNode(
                  autoCombinedCartItems[match.cartItemId]
                ),
                comboItem,
                pathToItem
              ); // Handle nested modifiers
            } else {
              logger.log(
                `Can not find the matched cartItemId ${match.cartItemId}`
              );
            }

            const quantity = autoCombinedCartItemsQuantity[match.cartItemId];
            if (quantity > 1) {
              autoCombinedCartItemsQuantity[match.cartItemId] -= 1;
            } else {
              delete autoCombinedCartItems[String(match.cartItemId)];
              delete autoCombinedCartItemsQuantity[match.cartItemId];
            }
          });
          currSequenceId += 1;
          dispatch(setSequenceId(currSequenceId)); // Update the sequence id in cart state to avoid duplicate cart item id between auto combo items and cart items
          i = -1;
        }
      }

      dispatch(
        setAutoCombinedCart({
          autoCombinedCartItems,
          autoCombinedCartItemsQuantity,
        })
      );

      // Trigger sendOrder after forming auto combo and there is at least one valid cart item
      const validAutoCombinedCartItems = Object.values(
        getValidCartItems(autoCombinedCartItems)
      );
      if (validAutoCombinedCartItems.length) dispatch(sendOrder({}));

      dispatch(dialogActions.setModGroupTabIndex(0));
      dispatch(dialogActions.updateSelectedModGroup());

      // Update the select item to be the regular cart item before auto combined item as it's not selectable
      const items = Object.values(autoCombinedCartItems);
      const firstAutoCombinedCartItemIdx = items.findIndex(
        (item) =>
          item.autoCombinedCartItemIds &&
          item.autoCombinedCartItemIds.length > 0
      );

      if (firstAutoCombinedCartItemIdx) {
        const selectedItem = items[firstAutoCombinedCartItemIdx - 1];
        dispatch(
          dialogActions.updateSelectedItem({
            item: items[firstAutoCombinedCartItemIdx - 1],
            itemCartId: selectedItem.cartItemId,
          })
        );
      } else {
        dispatch(dialogActions.updateSelectedItem(undefined));
      }
    } catch (error) {
      logger.error({
        message: 'Form Auto Combo Failed',
        error: JSON.stringify(error),
      });
    }
  }
);

export const addItemToCart = createAsyncThunk(
  'menu/addItemToCart',
  async (
    {
      menuItem,
      quantity,
      addedBy = CartItemAdditionTypes.human,
      prefixWord,
      inputModSymbol,
    }: IAddItemToCart,
    { dispatch, getState }
  ) => {
    const {
      cart: { modality, sequenceId: cartSequenceId },
      menu: { menuRes, persistentVoiceProps, modSymbolMapping },
    } = getState();

    if (menuRes) {
      const parsedMenuItem = buildFullMenuItem(
        menuItem,
        menuRes,
        persistentVoiceProps
      );
      dispatch(
        cartActions.addItemToCart({
          ...parsedMenuItem,
          cartItemId: cartSequenceId,
          childModifierGroups: {},
          modality,
          modcode: menuItem.modcode,
          addedBy,
        })
      );
      if (quantity) {
        dispatch(
          cartActions.updateQuantity({
            cartItemId: String(cartSequenceId),
            quantity,
          })
        );
      }

      if (prefixWord) {
        // handle prefixWord by selecting it as modifier if it is found
        Object.values(parsedMenuItem.modifierGroups).every((modGroup) => {
          const target = Object.values(modGroup.menuItems).find(
            (menuItem) =>
              menuItem.name.toLowerCase() === prefixWord.toLowerCase()
          );
          if (target) {
            dispatch(
              cartActions.selectModifier({
                cartItemId: cartSequenceId,
                menuItem: target,
                modGroup: modGroup,
                selected: true,
                modCode: inputModSymbol || '',
                modSymbolMapping,
                posSettings: menuRes.posSettings,
              })
            );
            return false;
          }
          return true;
        });
      }

      dispatch(setFullMenuItem(parsedMenuItem));
    }
  }
);

export const addHypothesisItemToCart = createAsyncThunk(
  'menu/addHypothesisItemToCart',
  async (orderItem: EntityMenuItem, { dispatch, getState }) => {
    const {
      menu: { topLevelMenuItems, modSymbolMapping, codeNameMapping, menuRes },
      cart: { sequenceId: cartSequenceId },
    } = getState();

    const handleOrderItemChildren = (
      children: EntityMenuItem[],
      fullMainItem: ParsedMenuItem,
      currentCartItemId: number
    ) => {
      children.forEach((mod) => {
        //find the modifier group and modifier based on the modifier id
        let { modGroup, modifier } = getModifierAndModGroupFromHypotheses(
          fullMainItem,
          String(mod.id),
          mod.modifier_group_id
        );

        if (!modGroup || !modifier) {
          //find the modifier group and modifier based on the modifier name if they couldn't be found by id
          logger.error(
            `Can't find the modifier ${mod.id} or modifier group ${mod.modifier_group_id} based on the ids from the hypotheses in the menu`
          );

          const { modGroup: modGroupByName, modifier: modifierByName } =
            getModifierAndModGroupFromHypothesesByName(
              fullMainItem,
              mod.name,
              mod.modifier_group_id
            );
          modGroup = modGroupByName;
          modifier = modifierByName;
        }

        if (modifier && modGroup) {
          const modCode = mod.pos_specific_properties.modcode
            ? codeNameMapping[mod.pos_specific_properties.modcode].code
            : '';

          //TODO handle the quantity of modifier when we have the quantity selection function in the future. Now the quantity should default to 1.
          dispatch(
            cartActions.selectModifier({
              cartItemId: currentCartItemId,
              menuItem: modifier,
              modGroup,
              selected: true,
              modCode,
              modSymbolMapping,
              posSettings: menuRes?.posSettings || {},
            })
          );

          // Handle nested modifier
          if (mod.children.length > 0) {
            handleOrderItemChildren(mod.children, modifier, currentCartItemId);
          }
        } else {
          logger.error(
            `Can't find the modifier ${mod.name} with id ${mod.id} or modifier group ${mod.modifier_group_id} based on the id or name from the hypotheses in the menu`
          );
          const errorMessage = `can't find the modifier ${mod.name} with id ${mod.id} or modifier group ${mod.modifier_group_id} based on the ids from the hypothesis in the menu`;
          const payload: Partial<ErrorTransmissionMessage> = {
            data: { message: errorMessage },
          };
          dispatch(messagingActions.sendError(payload as any));
        }
      });
    };

    //use the first menu item with the id in the assumption of the same item will show up in the menu once
    let mainItem = Object.values(topLevelMenuItems).find(
      (menuItem) => String(orderItem.id) === menuItem.id
    );

    if (!mainItem) {
      // Find the item in menu by name if it couldn't be found by id
      logger.error(
        `Can't find the main item based on the id: ${orderItem.id} from the hypotheses in the menu`
      );

      mainItem = Object.values(topLevelMenuItems).find(
        (menuItem) => orderItem.name === menuItem.name
      );
    }

    if (mainItem) {
      let currentCartItemId = cartSequenceId;
      const inputModSymbol = orderItem.pos_specific_properties.modcode
        ? codeNameMapping[orderItem.pos_specific_properties.modcode].code
        : '';

      //add the main item to cart
      await dispatch(
        addItemToCart({
          menuItem: mainItem,
          quantity: orderItem.quantity,
          addedBy: CartItemAdditionTypes.AI,
          inputModSymbol,
        })
      );

      const {
        menu: { fullMenuItems },
      } = getState();

      const itemId = mainItem.id + '-' + mainItem.categoryId;
      const fullMainItem = fullMenuItems[itemId];

      const children = orderItem.children;
      if (children?.length > 0) {
        handleOrderItemChildren(children, fullMainItem, currentCartItemId);
      }

      let quantity = orderItem.quantity ? Number(orderItem.quantity) : 1;

      if (quantity > 1) {
        dispatch(
          cartActions.updateQuantity({
            cartItemId: currentCartItemId.toString(),
            quantity: Math.min(quantity, 19),
          })
        );
      }
      dispatch(
        dialogActions.updateSelectedItem({
          item: fullMainItem,
          itemCartId: currentCartItemId,
        })
      );
      dispatch(dialogActions.setModGroupTabIndex(0));
      dispatch(dialogActions.updateSelectedModGroup());
    } else {
      logger.error(
        `Can't find the main item based on the id: ${orderItem.id} or name: ${orderItem.name} from the hypotheses in the menu`
      );
      const errorMessage = `can't find the main item ${orderItem.id} based on the id from the hypothesis in the menu`;
      const payload: Partial<ErrorTransmissionMessage> = {
        data: { message: errorMessage },
      };
      dispatch(messagingActions.sendError(payload as any));
      logger.log(
        `Add dummy hypothesis item: ${orderItem.name} to cart with cart item id: ${cartSequenceId}`
      );
      dispatch(
        addDummyHypothesisItemToCart({
          ...orderItem,
          cartItemId: cartSequenceId,
        })
      );
    }
  }
);

export const fetchMenuVersions = createAsyncThunk(
  'menu/menuVersions',
  async (
    { restaurantCode: restaurant_code }: { restaurantCode: string },
    { getState }
  ) => {
    const {
      config: { MENU_API },
    } = getState();
    let filteredMenuVersions: IMenuVersion[] = [];
    let prodLiveVersion = {} as IMenuVersion;
    if (restaurant_code) {
      const menuVersions: { data: IMenuVersionsResponse[] } =
        await getMenuVersionsFromMenuAPI(MENU_API, {
          restaurant_code,
        });
      const { LIVE, PLAYGROUND } = MenuStages;
      const versionMapping = (menuVersions?.data || []).reduce(
        (
          list,
          {
            commit_id: commitId,
            created_at: createdAt,
            creator_first_name,
            creator_last_name,
            publisher_username: publisherUsername,
            publisher_first_name,
            publisher_last_name,
            creator_username: creatorUsername,
            id,
            stage,
            is_active: isActive,
            updated_at: updatedAt,
            comment,
          }
        ) => {
          const versionData = {
            commitId,
            createdAt,
            creatorUsername,
            creatorName: `${creator_first_name} ${creator_last_name}`,
            publisherUsername,
            publisherName: `${publisher_first_name} ${publisher_last_name}`,
            id,
            stage,
            isActive,
            updatedAt,
            comment,
          };
          if (stage.toUpperCase() === LIVE && isActive) {
            prodLiveVersion = versionData;
          }
          if (stage.toUpperCase() === PLAYGROUND) {
            list[commitId] = versionData;
          }
          return list;
        },
        {} as Record<string, IMenuVersion>
      );
      filteredMenuVersions = Object.values(versionMapping).sort(
        (a, b) => Number(b.commitId) - Number(a.commitId)
      );
    }
    return {
      menuVersions: [
        Object.assign({}, defaultMenuVersion),
        ...filteredMenuVersions,
      ],
      prodLiveVersion,
    };
  }
);

export const selectMenuVersion = createAsyncThunk(
  'menu/selectMenuVersion',
  async (currentMenuVersion: string, { getState, dispatch }) => {
    const {
      restaurant: {
        selectedRestaurantDetails: { timezone, restaurantCode },
        selectedStage: currentStage,
      },
    } = getState();

    if (restaurantCode) {
      dispatch(startLoading({}));
      dispatch(
        fetchMenu({
          currentMenuVersion,
          timezone,
          restaurantCode,
          currentStage,
        })
      );
    }
    return currentMenuVersion;
  }
);

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    considerTimePeriods: (
      state,
      action: PayloadAction<{
        modality: ModalityType;
        timezone: string;
        stage: string;
      }>
    ) => {
      if (state.categoriesWithTimePeriod.length && state.menuRes) {
        const { timezone, stage } = action.payload;
        state.availableCategoryWithTimePeriod = [
          ...considerTimePeriodCategory(
            state.categoriesWithTimePeriod,
            timezone
          ),
        ];
        const { menuItems, overrides, menuItemSettings, posSettings } =
          state.menuRes;
        const { topLevelMenuItems, codeNameMapping, modSymbolMapping } =
          parseMenuResponse({
            categories: [
              ...state.alwaysAvailableCategories,
              ...state.availableCategoryWithTimePeriod,
            ],
            menuRes: { menuItems, overrides, menuItemSettings, posSettings },
            persistentVoiceProps: state.persistentVoiceProps,
            unavailableItems: state.unavailableItems,
            stage,
          });
        state.topLevelMenuItems = topLevelMenuItems;
        state.codeNameMapping = codeNameMapping;
        state.modSymbolMapping = modSymbolMapping;
      }
    },
    setFullMenuItem: (state, action: PayloadAction<ParsedMenuItem>) => {
      const item = action.payload;
      const itemId = item.itemId;
      state.fullMenuItems[itemId] = item;
    },
    updateSelectedMenuVersion: (state, { payload }: PayloadAction<string>) => {
      state.selectedMenuVersion = payload;
    },
    resetMenu: (state) => {
      state.topLevelMenuItems = {};
      state.modSymbolMapping = {};
      state.codeNameMapping = {};
      state.menuRes = undefined;
      state.persistentVoiceProps = {};
      state.categoriesWithTimePeriod = [];
      state.availableCategoryWithTimePeriod = [];
      state.alwaysAvailableCategories = [];
      state.unavailableItems = {};
    },
    updateMenuItems: (state, action) => {
      const {
        topLevelMenuItems,
        processedCategoryAndTimePeriodJSON,
        processedMenuJSON,
        processedModGroupsJSON,
        categoriesWithTimePeriod,
        availableCategoryWithTimePeriod,
        alwaysAvailableCategories,
        persistentVoiceProps,
        codeNameMapping,
        modSymbolMapping,
        unavailableItems,
        autoComboPrpIds,
        comboPrpIdToALaCartePrpId,
      } = action.payload;
      state.topLevelMenuItems = topLevelMenuItems;
      logger.info({
        message: 'Updating top level menu items',
        moreInfo: JSON.stringify(state.topLevelMenuItems),
      });
      state.modSymbolMapping = modSymbolMapping;
      state.codeNameMapping = codeNameMapping;
      state.menuRes = {
        ...processedCategoryAndTimePeriodJSON,
        ...processedMenuJSON,
        ...processedModGroupsJSON,
      };
      state.persistentVoiceProps = persistentVoiceProps;
      state.categoriesWithTimePeriod = categoriesWithTimePeriod;
      state.availableCategoryWithTimePeriod = availableCategoryWithTimePeriod;
      state.alwaysAvailableCategories = alwaysAvailableCategories;
      state.unavailableItems = unavailableItems;
      state.autoComboPrpIds = autoComboPrpIds;
      state.comboPrpIdToALaCartePrpId = comboPrpIdToALaCartePrpId;
    },
    resetVersions: (state) => {
      state.menuVersions = [];
      state.prodLiveVersion = {} as IMenuVersion;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchMenuVersions.fulfilled,
      (state, { payload: { menuVersions, prodLiveVersion } }) => {
        state.menuVersions = menuVersions;
        state.prodLiveVersion = prodLiveVersion;
      }
    );
    builder.addCase(selectMenuVersion.fulfilled, (state, action) => {
      state.selectedMenuVersion = action.payload;
    });
  },
});

export const menuActions = menuSlice.actions;
export const {
  considerTimePeriods,
  setFullMenuItem,
  updateSelectedMenuVersion,
  updateMenuItems,
  resetMenu,
  resetVersions,
} = menuSlice.actions;

export default menuSlice.reducer;
