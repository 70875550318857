import { produce } from 'immer';
import moment from 'moment-timezone';
import { snakeToCamelCase } from '.';
import { RootState } from '../app/store';
import { MenuStages } from '../constants/enums';
import {
  Category,
  CategoryAndTimePeriodQueryQuery,
  MenuItem,
  MenuItemSetting,
  MenuItemSettingKey,
  MenuItemsQueryQuery,
  MenuOverride,
  ModalityType,
  ModifierGroup,
  ModifierGroupsQueryQuery,
  OverrideKey,
  PosProperties,
  RestaurantInfoQuery,
  SecondaryType,
  TimePeriod,
} from '../generated-interfaces/graphql';
import { RestaurantSettings } from '../reducers/restaurantSlice';
import {
  IMenuData,
  IMenusOrderData,
} from '../redux/features/cachedMenu/cachedMenu.props';
import {
  addMenuData,
  updateMenuData,
} from '../redux/features/cachedMenu/cachedMenu.slice';
import { IFetchUnavailableItem, IUnavailableItem } from '../types';
import { memoize } from './cache';
import { CartItem, getCartItemNonDummyNode, getPosPropertyValue } from './cart';
import { VOICE_PROPERTIES } from './constants';
import logger from './logger';
import {
  ExpandName,
  ModSymbolCodeNameMappingType,
  generateModSymbolMapping,
} from './mappings';
import {
  getGraphQLClient,
  getMenuFromMenuAPI,
  getMenuURLFromMenuAPI,
  getPersistentMenuPropByRestaurant,
} from './network';
import { GenericMap, Override } from './types';
import { IComboItemMatch } from '../reducers/menuSlice';
import { A_LA_CARTE_POS_ID, DUMMY_ITEM, POS_ID, POS_NAME } from '../constants';

type AlwaysAvailableTimePeriod = TimePeriod;
export const alwaysAvailableTimePeriod: AlwaysAvailableTimePeriod = {
  id: '-1',
  startDate: null,
  endDate: null,
  description: null,
  availability: [0, 1, 2, 3, 4, 5, 6].map((day) => {
    return {
      day,
      hours: [],
      alwaysEnabled: true,
    };
  }),
  timePeriodCategoryMappings: [],
};

export function convertToMap<T extends { id: string | number }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.id] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export function formatMenuResponse(menuRes: MenuItemsQueryQuery) {
  return {
    menuItems: convertToMap(menuRes.menuItems),
    overrides: convertToMap(menuRes.menuOverrides),
    menuItemSettings: convertToMap(menuRes.menuItemSettings),
    posSettings: convertToMap(menuRes.posProperties),
  } as {
    menuItems: GenericMap<MenuItem>;
    overrides: GenericMap<MenuOverride>;
    menuItemSettings: GenericMap<MenuItemSetting>;
    posSettings: GenericMap<PosProperties>;
  };
}

export function formatCategoryAndTimePeriodResponse(
  categoryAndTimePeriodRes: CategoryAndTimePeriodQueryQuery
) {
  return {
    categories: convertToMap(categoryAndTimePeriodRes.categories),
    timePeriods: convertToMap(categoryAndTimePeriodRes.timePeriods),
  } as {
    categories: GenericMap<Category>;
    timePeriods: GenericMap<TimePeriod>;
  };
}

export function formatModGroupResponse(
  modifierGroupRes: ModifierGroupsQueryQuery
) {
  return {
    modifierGroups: convertToMap(modifierGroupRes.modifierGroups),
  } as {
    modifierGroups: GenericMap<ModifierGroup>;
  };
}

// "Re-Flatten" menu items but AFTER setting the category and children and stuff for easier use later
export function flattenMenuItems(
  menuItems: GenericMap<ParsedMenuItem>
): GenericMap<ParsedMenuItem> {
  return Object.values(menuItems).reduce((acc, item) => {
    if (!acc[item.itemId]) {
      acc[item.itemId] = item;
      for (let childModGroup of Object.values(item.modifierGroups)) {
        if (!flattenedModGroups[childModGroup.id]) {
          flattenedModGroups[childModGroup.id] = childModGroup;
          Object.assign(acc, flattenMenuItems(childModGroup.menuItems));
        }
      }
    }
    return acc;
  }, {} as { [itemId: string]: ParsedMenuItem });
}

export type ParsedModifierGroup = Override<
  ModifierGroup,
  ExtraModifierGroupProps
>;

/*
 * if
 * A -> A .. childModifer is excluded.
 *
 * if
 * A -> B -> A
 * ... We get in a loop.
 *
 * How do we prevent the HITL from choking?
 * (Without exploding into 100,000,000 menu items)
 *
 * So... We only go down one level!
 */
function parseROPModifierGroup(
  modifierGroupId: string,
  menuRes: MenuResponses,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>,
  history: MenuItem[] = []
): ParsedModifierGroup {
  if (parsedModifierGroups[modifierGroupId]) {
    return parsedModifierGroups[modifierGroupId];
  }

  const modifierGroup = menuRes.modifierGroups[modifierGroupId];

  const {
    displayName: modGroupDisplayName,
    description: modGroupDescription,
    isUpsell,
    isUpsellPrompt,
    label,
  } = getVoiceProps(modifierGroup.name, persistentVoiceProps);
  const itemsToAdd = modifierGroup.menuItems
    .filter(
      (menuItemId) =>
        !history.map((menuItem) => menuItem.id).includes('' + menuItemId)
    )
    .map((menuItemId) => ({
      ...parseROPMenuItem(
        String(menuItemId),
        modifierGroup.id,
        menuRes,
        persistentVoiceProps,
        history
      ),
      parentModifierGroupName: modifierGroup.name,
      parentModifierGroupDisplayName: modGroupDisplayName,
      parentModifierGroupId: modifierGroup.id,
    }))
    .filter((item) => item.available || isItem86edToday(item));

  const result = {
    ...modifierGroup,
    name: modGroupDisplayName ? modGroupDisplayName : modifierGroup.name,
    description: modGroupDescription
      ? modGroupDescription
      : modifierGroup.description,
    menuItems: convertToMap(itemsToAdd),
    prpName: modifierGroup.name,
    ...(isUpsell !== undefined && { isUpsell }),
    ...(isUpsellPrompt !== undefined && { isUpsellPrompt }),
    ...(label && { label }),
  };
  parsedModifierGroups[modifierGroupId] = result;
  return result;
}

interface ExtraMenuItemProps {
  category: string;
  categoryId: string | null;
  itemId: string;
  synonyms?: string;
  originalMenuItemId: string;
  containsOwnModifierGroup: boolean;
  sortOrderByCategory: number | null;
  sortOrderByModifierGroup: number | null;
  modifierGroups: GenericMap<ParsedModifierGroup>;
  overrides: GenericMap<MenuOverride>;
  menuItemSettings: GenericMap<MenuItemSetting>;
  posProperties: GenericMap<PosProperties>;
  isUpsell?: boolean;
  isUpsellPrompt?: boolean;
  label?: string;
  parentModifierGroupName?: string;
  parentModifierGroupDisplayName?: string;
  parentModifierGroupId?: string;
}

interface ExtraModifierGroupProps {
  menuItems: GenericMap<ParsedMenuItem>;
  prpName: string;
  isUpsell?: boolean;
  isUpsellPrompt?: boolean;
  label?: string;
  posProperties?: Record<string, PosProperties>;
}

export type ParsedMenuItem = Override<MenuItem, ExtraMenuItemProps>;

const parsedMenuItems: GenericMap<ParsedMenuItem> = {}; // key should be combination of menu item id + modifier group id/category id
const parsedModifierGroups: GenericMap<ParsedModifierGroup> = {};
const parsedModAvailability: GenericMap<GenericMap<ParsedModifierGroup>> = {};
const flattenedModGroups: GenericMap<ParsedModifierGroup> = {};

export interface MenuResponses {
  menuItems: GenericMap<MenuItem>;
  overrides: GenericMap<MenuOverride>;
  menuItemSettings: GenericMap<MenuItemSetting>;
  posSettings: GenericMap<PosProperties>;
  categories: GenericMap<Category>;
  timePeriods: GenericMap<TimePeriod>;
  modifierGroups: GenericMap<ModifierGroup>;
}

export const getPosProperties = (
  menuItem: MenuItem,
  menuRes: MenuResponses
) => {
  return (
    menuItem?.posProperties?.reduce((pps, id) => {
      if (menuRes?.posSettings[id]) pps[id] = menuRes.posSettings[id];
      return pps;
    }, {} as GenericMap<PosProperties>) || {}
  );
};

export function buildFullMenuItem(
  item: TopLevelMenuItem,
  menuRes: MenuResponses,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>
): ParsedMenuItem {
  const menuItem = menuRes.menuItems[item.id];
  const categoryId = item.categoryId;
  const itemId = item.id + '-' + categoryId;
  const filteredCategory = menuRes.categories[categoryId].sortOrder.filter(
    (order) => order.id === Number(item.id)
  );

  const {
    displayName: menuItemDisplayName,
    description: menuItemDescription,
    isUpsell,
    isUpsellPrompt,
    label,
  } = getVoiceProps(menuItem.name, persistentVoiceProps);

  const modifierGroups = menuItem.modifierGroups.map((modifierGroupId) =>
    parseROPModifierGroup(
      String(modifierGroupId),
      menuRes,
      persistentVoiceProps
    )
  );

  const modifierGroupsMap = convertToMap(modifierGroups);

  const posProperties = getPosProperties(menuItem, menuRes);

  const result = {
    ...menuItem,
    name: ExpandName(menuItemDisplayName ? menuItemDisplayName : menuItem.name),
    description: menuItemDescription
      ? menuItemDescription
      : menuItem.description,
    category: item.category,
    categoryId: item.categoryId,
    itemId,
    originalMenuItemId: String(item.id),
    containsOwnModifierGroup: false,
    // Dinero library requires the price in integer cents
    price: Math.round(menuItem.price * 100),
    imageUrl: menuItem.imageUrl ?? '/no_menu_image.jpg',
    sortOrderByCategory:
      filteredCategory.length > 0 ? filteredCategory[0].sortOrder : null,
    sortOrderByModifierGroup: null,
    modifierGroups: modifierGroupsMap,
    overrides: convertToMap(
      menuItem.menuOverrides
        .map((id) => menuRes.overrides[id])
        .filter((override) => {
          return (
            override.secondaryType === null ||
            (override.secondaryType === SecondaryType.Category &&
              override.secondaryId === categoryId)
          );
        })
    ),
    menuItemSettings: convertToMap(
      menuItem.menuItemSettings
        .map((id) => menuRes.menuItemSettings[id])
        .filter((setting) => {
          return String(setting.menuItemId) === menuItem.id;
        })
    ),
    posProperties,
    ...(isUpsell !== undefined && { isUpsell }),
    ...(isUpsellPrompt !== undefined && { isUpsellPrompt }),
    ...(label && { label }),
  };
  // use itemId as the unique id to store parsed menu items
  parsedMenuItems[itemId] = result;
  return result;
}

function parseROPMenuItem(
  menuItemId: string,
  parentModifierGroupId: string,
  menuRes: MenuResponses,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>,
  history: MenuItem[] = []
): ParsedMenuItem {
  const itemId = menuItemId + '-' + parentModifierGroupId;
  if (parsedMenuItems[itemId]) {
    return parsedMenuItems[itemId];
  }

  const menuItem: MenuItem = menuRes.menuItems[menuItemId];
  const parentModifierGroup = menuRes.modifierGroups[parentModifierGroupId];
  const {
    displayName: menuItemDisplayName,
    description: menuItemDescription,
    isUpsell,
    isUpsellPrompt,
    label,
    synonyms,
  } = getVoiceProps(menuItem.name, persistentVoiceProps);

  const result = {
    ...menuItem,
    name: ExpandName(menuItemDisplayName ? menuItemDisplayName : menuItem.name),
    description: menuItemDescription
      ? menuItemDescription
      : menuItem.description,
    category: 'modifier',
    categoryId: null,
    containsOwnModifierGroup: false,
    itemId: menuItemId,
    originalMenuItemId: menuItemId,
    // Dinero library requires the price in integer cents
    price: Math.round(menuItem.price * 100),
    imageUrl: menuItem.imageUrl ?? '/no_menu_image.jpg',
    sortOrderByCategory: null,
    sortOrderByModifierGroup:
      parentModifierGroup.sortOrder.filter(
        (order) => String(order.id) === menuItemId
      ).length > 0
        ? parentModifierGroup.sortOrder.filter(
            (order) => String(order.id) === menuItemId
          )[0].sortOrder
        : null,
    modifierGroups: {},
    overrides: convertToMap(
      menuItem.menuOverrides
        .map((id) => menuRes.overrides[id])
        .filter((override) => {
          return (
            override.secondaryType === null ||
            (override.secondaryType === SecondaryType.ModifierGroup &&
              override.secondaryId === parentModifierGroupId)
          );
        })
    ),
    menuItemSettings: convertToMap(
      menuItem.menuItemSettings
        .map((id) => menuRes.menuItemSettings[id])
        .filter((setting) => {
          return String(setting.menuItemId) === menuItem.id;
        })
    ),
    posProperties: convertToMap(
      Object.values(menuRes.posSettings).filter(
        (setting) =>
          setting.objectPrimaryKey === menuItem.id &&
          setting.propertyType === 'MENU_ITEM'
      )
    ),
    ...(isUpsell !== undefined && { isUpsell }),
    ...(isUpsellPrompt !== undefined && { isUpsellPrompt }),
    ...(label && { label }),
    synonyms: synonyms || '',
  };

  if (history.length < 10) {
    // use itemId as the unique id to store parsed menu items
    parsedMenuItems[itemId] = result;
    // Recurse after
    result.containsOwnModifierGroup = menuItem.modifierGroups.includes(
      parseInt(parentModifierGroupId)
    );
    result.modifierGroups = convertToMap(
      menuItem.modifierGroups
        .filter(
          (modifierGroupId) => String(modifierGroupId) !== parentModifierGroupId
        )
        .map((modifierGroupId) =>
          parseROPModifierGroup(
            String(modifierGroupId),
            menuRes,
            persistentVoiceProps,
            history.concat(menuItem)
          )
        )
    );
  }
  return result;
}

export type ParsedCategory = Override<
  Category,
  { timePeriods: GenericMap<TimePeriod>; activeTimePeriod?: ActiveTimePeriod }
>;

export function parseCategoryAndTimeperiodResponse(
  categoryAndTimePeriodRes: ReturnType<
    typeof formatCategoryAndTimePeriodResponse
  >
) {
  const categoriesWithTimePeriod: ParsedCategory[] = [];
  const alwaysAvailableCategories: ParsedCategory[] = [];
  Object.values(categoryAndTimePeriodRes.categories)
    .sort((a, b) => {
      // sort categories by ownSortOrder
      if (a.ownSortOrder !== null && b.ownSortOrder !== null) {
        return a.ownSortOrder - b.ownSortOrder;
      } else if (a.ownSortOrder !== null) {
        return -1;
      } else if (b.ownSortOrder !== null) {
        return 1;
      }
      return 0;
    })
    .forEach((category) => {
      if (!category.timePeriods.length) {
        alwaysAvailableCategories.push({
          ...category,
          timePeriods: convertToMap([alwaysAvailableTimePeriod]),
        });
      } else {
        categoriesWithTimePeriod.push({
          ...category,
          timePeriods: convertToMap(
            category.timePeriods.map(
              (id) => categoryAndTimePeriodRes.timePeriods[id]
            )
          ),
        });
      }
    });
  const autoComboCategory = [
    ...categoriesWithTimePeriod,
    ...alwaysAvailableCategories,
  ].find((category) => category.name === 'Auto Combos');

  const autoComboPrpIds =
    autoComboCategory && autoComboCategory.menuItems
      ? autoComboCategory.menuItems
      : [];
  let sortedAutoComboPrpIds;
  if (autoComboCategory?.sortOrder) {
    const autoComboCategorySortOrder = convertToMap(
      autoComboCategory?.sortOrder
    );
    sortedAutoComboPrpIds =
      autoComboPrpIds
        .slice()
        .sort((a, b) => {
          if (autoComboCategorySortOrder[a] && autoComboCategorySortOrder[b]) {
            const aSortOrder = autoComboCategorySortOrder[a].sortOrder;
            const bSortOrder = autoComboCategorySortOrder[b].sortOrder;
            if (aSortOrder !== null && bSortOrder !== null) {
              return aSortOrder - bSortOrder;
            }
          } else if (autoComboCategorySortOrder[a]) {
            return -1;
          } else if (autoComboCategorySortOrder[b]) {
            return 1;
          }
          return 0;
        })
        .map((id) => String(id)) || [];
  }

  return {
    categoriesWithTimePeriod,
    alwaysAvailableCategories,
    autoComboPrpIds: sortedAutoComboPrpIds
      ? sortedAutoComboPrpIds
      : autoComboPrpIds.map((id) => String(id)),
  };
}

export type TopLevelMenuItem = {
  category: string;
  categoryId: string;
  id: string;
  name: string;
  available: boolean;
  unavailableUntil: string | null;
  modcode?: string;
  speak?: string;
  synonyms?: string;
};

export function parseMenuResponse({
  categories,
  menuRes,
  persistentVoiceProps,
  unavailableItems,
  stage,
}: {
  categories: ParsedCategory[];
  menuRes: ReturnType<typeof formatMenuResponse>;
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  unavailableItems: Record<string, IUnavailableItem>;
  stage: string;
}) {
  let modSymbolMapping: ModSymbolCodeNameMappingType = {};
  let codeNameMapping: ModSymbolCodeNameMappingType = {};

  categories.sort((a, b) => {
    // sort categories by ownSortOrder
    if (a.ownSortOrder !== null && b.ownSortOrder !== null) {
      return a.ownSortOrder - b.ownSortOrder;
    } else if (a.ownSortOrder !== null) {
      return -1;
    } else if (b.ownSortOrder !== null) {
      return 1;
    }
    return 0;
  });

  const topLevelMenuItems = categories.reduce((acc, category) => {
    category.menuItems
      .filter((id) => {
        if (!menuRes.menuItems[id]) {
          return false;
        }
        const { available, unavailableUntil } = getUnAvailableUntilAndAvailable(
          { item: menuRes.menuItems[id], stage, unavailableItems }
        );
        const is86edIndefinitely = isItem86edIndefinitely(
          available,
          unavailableUntil
        );
        return !menuRes.menuItems[id].isModifierOnly && !is86edIndefinitely;
      })
      .forEach((menuItemId) => {
        const { displayName: categoryDisplayName } = getVoiceProps(
          category.name,
          persistentVoiceProps
        );
        const currentItem = menuRes.menuItems[menuItemId];
        const { name } = currentItem;
        const { available, unavailableUntil } = getUnAvailableUntilAndAvailable(
          { item: currentItem, stage, unavailableItems }
        );
        const { displayName: menuItemDisplayName, synonyms } = getVoiceProps(
          name,
          persistentVoiceProps
        );

        const menuItem: TopLevelMenuItem = {
          category: ExpandName(
            categoryDisplayName ? categoryDisplayName : category.name
          ),
          categoryId: category.id,
          id: String(menuItemId),
          name: ExpandName(menuItemDisplayName ? menuItemDisplayName : name),
          synonyms: synonyms || '',
          available: available,
          unavailableUntil: unavailableUntil,
        };
        acc[menuItem.id] = { ...menuItem };
      });
    if (category.name === '__omnimod__') {
      const [parsedModSymbolMapping, parsedCodeNameMapping] =
        generateModSymbolMapping(category, menuRes);
      modSymbolMapping = parsedModSymbolMapping;
      codeNameMapping = parsedCodeNameMapping;
    }
    return acc;
  }, {} as GenericMap<TopLevelMenuItem>);

  return { topLevelMenuItems, modSymbolMapping, codeNameMapping };
}

function getMenuOverrides(menuItem: ParsedMenuItem, modality: ModalityType) {
  const isCategoryLevelItem = !!menuItem.categoryId;
  const isModifierLevelItem = menuItem.category === 'modifier';
  let overrides: any[] = [];
  if (menuItem.overrides) {
    overrides = Object.values(menuItem.overrides).filter(
      (o) => o.modalityType === modality
    );
  }
  if (isCategoryLevelItem) {
    overrides = overrides.filter(
      (o) =>
        o.secondaryType === SecondaryType.Category || o.secondaryType === null
    );
  } else if (isModifierLevelItem) {
    overrides = overrides.filter(
      (o) =>
        o.secondaryType === SecondaryType.ModifierGroup ||
        o.secondaryType === null
    );
  }

  const secondaryTypeOverride = overrides.filter(
    (o) => o.secondaryType !== null
  );
  const modalityTypeOverride = overrides.filter(
    (o) => o.secondaryType === null
  );
  return {
    secondaryTypeOverride,
    modalityTypeOverride,
  };
}

function isItemEnabledByModality(
  menuItem: ParsedMenuItem,
  modalityState: ModalityType
) {
  let requiredSettingKey: MenuItemSettingKey;
  switch (modalityState) {
    case ModalityType.Dinein:
      requiredSettingKey = MenuItemSettingKey.IsDineInEnabled;
      break;
    case ModalityType.Togo:
      requiredSettingKey = MenuItemSettingKey.IsToGoEnabled;
      break;
    case ModalityType.Delivery:
      requiredSettingKey = MenuItemSettingKey.IsDeliveryEnabled;
      break;
  }
  return !!Object.values(menuItem.menuItemSettings).find(
    (setting) => setting.key === requiredSettingKey && setting.value === 'true'
  );
}

export type PRPRestaurantSettings =
  RestaurantInfoQuery['restaurant']['restaurantSettings'];

export function getDefaultTaxRateForModality(
  modality: ModalityType,
  restaurantSettings?: RestaurantSettings
) {
  if (!restaurantSettings) {
    return 0;
  }
  switch (modality) {
    case ModalityType.Togo:
      return restaurantSettings.toGoModalityTaxRate;
    case ModalityType.Delivery:
      return restaurantSettings.deliveryModalityTaxRate;
    case ModalityType.Dinein:
    default:
      return restaurantSettings.dineInModalityTaxRate;
  }
}

export function getMenuItemTax(
  menuItem: ParsedMenuItem,
  modality: ModalityType,
  restaurantSettings?: RestaurantSettings
): number {
  let actualModality = modality;
  const { modalityTypeOverride } = getMenuOverrides(menuItem, actualModality);
  const taxOverride = modalityTypeOverride.find(
    (o) => o.overrideKey === OverrideKey.Tax
  );
  if (taxOverride !== undefined) {
    return parseFloat(taxOverride.overrideValue);
  } else if (modality === ModalityType.Dinein && menuItem.tax != null) {
    return menuItem.tax;
  } else {
    return getDefaultTaxRateForModality(modality, restaurantSettings);
  }
}

export function getMenuItemPrice(
  menuItem: ParsedMenuItem,
  modality: ModalityType,
  quantity?: number
) {
  let actualModality = modality;
  const { modalityTypeOverride, secondaryTypeOverride } = getMenuOverrides(
    menuItem,
    actualModality
  );
  const priceOverride =
    secondaryTypeOverride.find((o) => o.overrideKey === OverrideKey.Price) ||
    modalityTypeOverride.find((o) => o.overrideKey === OverrideKey.Price);
  const price =
    priceOverride === undefined
      ? menuItem.price
      : Math.round(parseFloat(priceOverride.overrideValue) * 100);
  return price * (quantity || 1);
}

export function updateCartItemModality(
  cartItem: CartItem,
  modality: ModalityType
) {
  if (cartItem.modality === modality) {
    return null;
  }
  cartItem.modality = modality;
  Object.keys(cartItem.childModifierGroups).forEach((modGroupId) => {
    const modGroup = cartItem.childModifierGroups[modGroupId];
    Object.keys(modGroup.selectedItems).forEach((cartItemId) => {
      const updatedCartItem = updateCartItemModality(
        modGroup.selectedItems[cartItemId],
        modality
      );
      if (updatedCartItem) {
        modGroup.selectedItems[cartItemId] = updatedCartItem;
      }
    });
  });
  return cartItem;
}

export function updateCartPrices(
  cartItem: CartItem,
  menuItems: GenericMap<ParsedMenuItem>,
  modality: ModalityType
): CartItem | null {
  let updated = false;
  let updatedCartItem = cartItem;
  const menuItem = menuItems[cartItem.itemId];
  if (menuItem) {
    const cartItemPrice = getMenuItemPrice(cartItem, cartItem.modality);
    const menuItemPrice = getMenuItemPrice(menuItem, modality);
    if (menuItemPrice !== cartItemPrice) {
      updatedCartItem = produce(updatedCartItem, (cartItem) => {
        cartItem.price = menuItemPrice;
      });
      updated = true;
    }
    for (let cartModGroup of Object.values(cartItem.childModifierGroups)) {
      const modGroup =
        menuItem.modifierGroups[cartModGroup.menuModifierGroupId];
      for (let item of Object.values(cartModGroup.selectedItems)) {
        const updatedItem = updateCartPrices(
          item,
          modGroup.menuItems,
          modality
        );
        if (updatedItem) {
          updatedCartItem = produce(updatedCartItem, (cartItem) => {
            cartItem.childModifierGroups[
              cartModGroup.menuModifierGroupId
            ].selectedItems[updatedItem.itemId] = updatedItem;
          });
          updated = true;
        }
      }
    }
  }
  return updated ? updatedCartItem : null;
}

export function checkForRequiredAndNotFreeModifier(
  item: ParsedMenuItem
): boolean {
  let result = false;
  firstLevelLoop: for (let modifierGroup of Object.values(
    item.modifierGroups
  )) {
    if (modifierGroup.minimumSelections > 0) {
      for (let child of Object.values(modifierGroup.menuItems)) {
        if (child.price > 0) {
          // if a priced modifier is found, no need to check the rest of modifiers
          result = true;
          break firstLevelLoop;
        }
      }
    }
  }
  return result;
}

export function findTopLevelCartItem(
  wantedCartItem: CartItem,
  cartItems: { [cartId: string]: CartItem },
  topLevelItem?: CartItem
): CartItem | null {
  for (let cartItem of Object.values(cartItems)) {
    if (cartItem.cartItemId === wantedCartItem.cartItemId) {
      if (topLevelItem) {
        return topLevelItem;
      }
      return cartItem;
    }
    const modGroups = Object.values(cartItem.childModifierGroups);
    for (let modGroup of modGroups) {
      const found = findTopLevelCartItem(
        wantedCartItem,
        modGroup.selectedItems,
        topLevelItem ? topLevelItem : cartItem
      );
      if (found) {
        return found;
      }
    }
  }
  return null;
}

// Takes in menu items flattened as we get from webservice instead of in the nested mod group form
export function checkForNoLongerAvailableItems(
  cartItems: GenericMap<CartItem>,
  parsedMenuItems: GenericMap<ParsedMenuItem>
): CartItem[] {
  const unavailableItems: CartItem[] = [];
  for (let cartItem of Object.values(cartItems)) {
    const menuItem = parsedMenuItems[cartItem.itemId];
    if (!menuItem) {
      unavailableItems.push(cartItem);
      continue;
    }

    for (let modGroup of Object.values(cartItem.childModifierGroups)) {
      unavailableItems.push(
        ...checkForNoLongerAvailableItems(
          modGroup.selectedItems,
          parsedMenuItems
        )
      );
    }
  }
  return unavailableItems;
}

function considerItemAvailability(
  menuItems: GenericMap<ParsedMenuItem>,
  modalityState: ModalityType
): GenericMap<ParsedMenuItem> {
  return Object.values(menuItems).reduce((acc, menuItem) => {
    if (!menuItem.available) {
      return acc; // Don't return 86'ed items
    }

    if (!isItemEnabledByModality(menuItem, modalityState)) {
      return acc;
    }

    acc[menuItem.itemId] = { ...menuItem };

    let updatedModGroups: GenericMap<ParsedModifierGroup> = {};
    if (parsedModAvailability[menuItem.itemId]) {
      updatedModGroups = parsedModAvailability[menuItem.itemId];
    } else {
      parsedModAvailability[menuItem.itemId] = updatedModGroups;
      for (let modGroup of Object.values(menuItem.modifierGroups)) {
        const updatedModGroup = { ...modGroup };
        updatedModGroup.menuItems = considerItemAvailability(
          modGroup.menuItems,
          modalityState
        );
        updatedModGroups[modGroup.id] = updatedModGroup;
      }
    }

    acc[menuItem.itemId].modifierGroups = updatedModGroups;
    return acc;
  }, {} as GenericMap<ParsedMenuItem>);
}

type ActiveTimePeriod = {
  alwaysEnabled: boolean;
  day: string;
  start?: string;
  end?: string;
};

export function considerTimePeriodCategory(
  categoryMap: ParsedCategory[],
  timezone: string
): ParsedCategory[] {
  return categoryMap
    .map((category) => ({
      ...category,
      activeTimePeriod: getTimePeriod(
        Object.values(category.timePeriods),
        timezone
      ),
    }))
    .filter((category) => category.activeTimePeriod);
}

export function getTimePeriod(
  timePeriods: TimePeriod[],
  timezone: string
): ActiveTimePeriod | undefined {
  const currentUTCTime = moment.utc().format();

  //use the timezone of the restaurant to create the moment objects
  moment.tz.setDefault(timezone);
  let current = moment
    .utc(currentUTCTime, 'YYYY-MM-DD HH-mm-ss Z')
    .tz(timezone);

  const day = current.weekday();

  for (let period of timePeriods) {
    const { availability, startDate, endDate } = period;
    const availabilityOfCurrent = availability.find((obj) => obj.day === day);

    const isDateSpecific = Boolean(startDate);

    const isWithinDates =
      !isDateSpecific ||
      (current.isSameOrAfter(startDate, 'date') &&
        (!endDate || current.isSameOrBefore(endDate, 'date')));

    if (isWithinDates && availabilityOfCurrent?.alwaysEnabled) {
      return { alwaysEnabled: true, day: current.format('dddd') };
    }

    if (isWithinDates && availabilityOfCurrent?.hours) {
      for (let timeRange of availabilityOfCurrent?.hours) {
        const updatedTimeRange = timeRange.map((time) => {
          let timeStr = String(time);
          while (timeStr.length < 4) {
            timeStr = '0' + timeStr;
          }
          return timeStr;
        });

        const startTime = current
          .clone()
          .set('hour', Number(updatedTimeRange[0].slice(0, 2)))
          .set('minute', Number(updatedTimeRange[0].slice(2)));

        const endTime = current
          .clone()
          .set('hour', Number(updatedTimeRange[1].slice(0, 2)))
          .set('minute', Number(updatedTimeRange[1].slice(2)));

        if (
          current.isSameOrBefore(endTime) &&
          current.isSameOrAfter(startTime)
        ) {
          return {
            alwaysEnabled: false,
            day: current.format('dddd'),
            start: startTime.format('h:mma'),
            end: endTime.format('h:mma'),
          };
        }
      }
    }
  }

  return undefined;
}

export function checkForUnavailableRequiredModifierGroup(
  modifierGroups: GenericMap<ParsedModifierGroup>
) {
  return !!Object.values(modifierGroups).find((modGroup) => {
    if (modGroup.minimumSelections > 0) {
      //check the modifier groups recursively
      const availableModsCounter = Object.values(modGroup.menuItems).filter(
        (child) =>
          child.available &&
          !checkForUnavailableRequiredModifierGroup(child.modifierGroups)
      ).length;
      if (availableModsCounter < modGroup.minimumSelections) {
        return true;
      }
    }
  });
}

export function checkForQuantityExceededItems(
  cartItems: GenericMap<CartItem>,
  parsedMenuItems: GenericMap<ParsedMenuItem>
): CartItem[] {
  const quantityExceededItems: CartItem[] = [];
  let quantityMapping: { [itemId: string]: number } = {};
  for (let cartItem of Object.values(cartItems)) {
    const itemId = cartItem.itemId;
    if (itemId in quantityMapping) {
      quantityMapping[itemId] += 1;
    } else {
      quantityMapping[itemId] = 1;
    }
  }
  for (let cartItem of Object.values(cartItems)) {
    const menuItem = parsedMenuItems[cartItem.itemId];
    if (
      menuItem &&
      menuItem.availableLimitedQuantity &&
      menuItem.availableLimitedQuantity < quantityMapping[menuItem.itemId]
    ) {
      const itemAlreadyExist = quantityExceededItems.find(
        (item) => item.itemId === cartItem.itemId
      );
      if (!itemAlreadyExist) {
        quantityExceededItems.push(cartItem);
      }
      continue;
    }

    for (let modGroup of Object.values(cartItem.childModifierGroups)) {
      quantityExceededItems.push(
        ...checkForQuantityExceededItems(
          modGroup.selectedItems,
          parsedMenuItems
        )
      );
    }
  }
  return quantityExceededItems;
}

export function considerSubCategory(categories: string[]) {
  let subCatMapping: {
    [category: string]: { hasSubCat: boolean; subCats: string[] };
  } = {};
  categories.forEach((cat) => {
    if (cat.indexOf(':') !== -1) {
      //there is a colon in the category name which should be a sub category in BJs menu
      const catName = cat.split(':')[0];
      if (!Object.keys(subCatMapping).includes(catName)) {
        subCatMapping[catName] = { hasSubCat: true, subCats: [] };
      }
      subCatMapping[catName].subCats?.push(cat);
    } else {
      subCatMapping[cat] = { hasSubCat: false, subCats: [] };
    }
  });
  return subCatMapping;
}

export function sortChildrenModGroup(cartItem: CartItem) {
  const modifierGroupsSortOrderMapping = cartItem.sortOrder.reduce(
    (acc: any, o: any) => {
      acc[String(o.id)] = o.sortOrder;
      return acc;
    },
    {} as { [id: string]: any }
  );

  return Object.values(cartItem.childModifierGroups).sort((a: any, b: any) => {
    if (
      modifierGroupsSortOrderMapping[a.menuModifierGroupId] !== null &&
      modifierGroupsSortOrderMapping[b.menuModifierGroupId] !== null
    ) {
      return (
        modifierGroupsSortOrderMapping[a.menuModifierGroupId] -
        modifierGroupsSortOrderMapping[b.menuModifierGroupId]
      );
    } else if (modifierGroupsSortOrderMapping[a.menuModifierGroupId] !== null) {
      return -1;
    } else if (modifierGroupsSortOrderMapping[b.menuModifierGroupId] !== null) {
      return 1;
    }
    return 0;
  });
}

export function sortModGroups(cartItem: CartItem | ParsedMenuItem) {
  const modifierGroupsSortOrderMapping = cartItem.sortOrder.reduce(
    (acc: any, o: any) => {
      acc[String(o.id)] = o.sortOrder;
      return acc;
    },
    {} as { [id: string]: any }
  );

  return Object.values(cartItem.modifierGroups).sort((a: any, b: any) => {
    if (
      modifierGroupsSortOrderMapping[a.id] !== null &&
      modifierGroupsSortOrderMapping[b.id] !== null
    ) {
      return (
        modifierGroupsSortOrderMapping[a.id] -
        modifierGroupsSortOrderMapping[b.id]
      );
    } else if (modifierGroupsSortOrderMapping[a.menuModifierGroupId] !== null) {
      return -1;
    } else if (modifierGroupsSortOrderMapping[b.menuModifierGroupId] !== null) {
      return 1;
    }
    return 0;
  });
}

export type PersistentMenuProperty = {
  property_id: number;
  restaurant_code: string;
  unique_identifier: string;
  property_type: string;
  property_key: string;
  property_value: any;
};

export interface IMenuVersionsResponse {
  id: string;
  restaurant_code: string;
  creator_username: string;
  creator_first_name: string;
  creator_last_name: string;
  commit_id: string;
  updated_at: string;
  created_at: string;
  stage: MenuStages;
  is_active: boolean;
  publisher_username: string;
  publisher_first_name: string;
  publisher_last_name: string;
  commit_created_at: string;
  comment: string;
}

export interface IMenuVersion {
  id: string;
  creatorUsername: string;
  creatorName: string;
  publisherUsername: string;
  publisherName: string;
  commitId: string;
  updatedAt: string;
  createdAt: string;
  comment: string;
  menuCommitUrl?: string;
  stage?: MenuStages;
  isActive: boolean;
}

enum PropertyValueKey {
  displayName = 'display_name',
  description = 'description',
  synonyms = '__synonym__',
  isUpsell = 'is_upsell',
  isUpsellPrompt = 'is_upsell_prompt',
  label = 'label',
}

export function getVoiceProps(
  uniqueIdentifier: string,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>
) {
  const voiceProps: Record<any, any> = {};
  if (uniqueIdentifier in persistentVoiceProps) {
    try {
      const persistentVoiceProp = JSON.parse(
        persistentVoiceProps[uniqueIdentifier].property_value
      );

      for (let key in PropertyValueKey) {
        const propertyValueKeyValue =
          PropertyValueKey[key as keyof typeof PropertyValueKey];
        if (persistentVoiceProp[propertyValueKeyValue] !== undefined) {
          voiceProps[key as keyof typeof PropertyValueKey] =
            persistentVoiceProp[propertyValueKeyValue];
        }
      }
    } catch (err) {
      logger.error({
        message: 'Parse voice props failed',
        error: err,
      });
    }
  }
  return voiceProps;
}

export function convertModGroupToMap<T extends { menuModifierGroupId: string }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.menuModifierGroupId] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export function isItem86edIndefinitely(
  available: boolean,
  unavailableUntil?: string
) {
  return !available && !unavailableUntil;
}

/**
 * TODO: Ideally we should add a boolean flag something like
 * "isUnavailableUntilTomorrow" to menu-item object.
 * See JIRA ticket: https://presto.atlassian.net/browse/PRV-4173
 */

/** Checks if a menu-item is 86ed for today */
export const isItem86edToday = memoize(
  (item: MenuItem | ParsedMenuItem | TopLevelMenuItem) => {
    if (item.available || !item.unavailableUntil) return false;

    const unavailableUntil = moment(item.unavailableUntil);

    return unavailableUntil.isAfter(new Date(), 'day');
  },
  (item) => `${item.available},${item.unavailableUntil}`
);

export const checkItemInTree = ({
  cartItem,
  pathToItem,
  fromSelectModifier,
}: {
  cartItem: CartItem;
  pathToItem: string;
  fromSelectModifier?: boolean;
}): any => {
  const ids = pathToItem.split('__');
  let splicedId = '';
  if (fromSelectModifier) {
    splicedId = ids.splice(-1, 1)[0] || '';
  }
  let currentItem: any = null;
  currentItem = ids.reduce((item: any, id: string) => {
    if (Object.values(item?.childModifierGroups || {}).length) {
      return item.childModifierGroups[id];
    } else if (Object.values(item?.selectedItems || {}).length) {
      return item.selectedItems[id];
    }
    return null;
  }, cartItem as any);
  if (fromSelectModifier && currentItem?.selectedItems) {
    return currentItem.selectedItems[splicedId];
  }
  return currentItem;
};

//If the current active version matches with the cached menu data version, return the menu data instead of making further API calls
export function isMenuInCache({
  restaurantCode,
  activeCommitId,
  cachedMenu: { menusById, menusOrder },
}: {
  restaurantCode: string;
  activeCommitId: string;
  cachedMenu: {
    menusById: GenericMap<IMenuData>;
    menusOrder: Array<IMenusOrderData>;
  };
}) {
  let storedMenuDetails = {
    menuJSON: {},
    modifierGroupJSON: {},
    categoryAndTimePeriodJSON: {},
    persistentMenuProperty: {},
  };

  const cachedRestaurantIndex = menusOrder.findIndex(
    ({ restaurantId }) => restaurantId === restaurantCode
  );

  if (
    cachedRestaurantIndex > -1 &&
    activeCommitId === menusOrder[cachedRestaurantIndex].menuVersion
  ) {
    storedMenuDetails = menusById[restaurantCode];
    logger.info({
      restaurantCode,
      message:
        'Function isMenuInCache: Cached menu data exists - not calling S3 dynamic menu URL for fetch',
    });
  } else {
    logger.info({
      restaurantCode,
      message:
        'Function isMenuInCache: No cached menu data exists - calling S3 dynamic menu URL for fetch',
    });
  }

  return {
    cachedRestaurantIndex,
    storedMenuDetails,
  };
}

export function formatUnavailableItems(
  unavailable_items: IFetchUnavailableItem[]
) {
  let formattedUnavailableItems: Record<string, IUnavailableItem> = {};

  if (unavailable_items?.length) {
    formattedUnavailableItems = unavailable_items.reduce((itemList, item) => {
      itemList[item.item_unique_identifier] = Object.entries(item).reduce(
        (acc: any, [key, value]) => {
          const updatedKey = snakeToCamelCase(key);
          acc[updatedKey] = value;
          return acc;
        },
        {} as IUnavailableItem
      );

      return itemList;
    }, {} as Record<string, IUnavailableItem>);
  }

  return formattedUnavailableItems;
}

export async function fetchMenuBasedOnStage({
  restaurantCode,
  state,
  currentMenuVersion,
  currentStage,
  dispatch,
}: {
  restaurantCode: string;
  state: RootState;
  currentMenuVersion: string;
  currentStage: MenuStages;
  dispatch: Function;
}) {
  let unavailableItems: Record<string, IUnavailableItem> = {};
  const {
    cachedMenu,
    config: { PRP_PERSISTENT_API, PRP_API, MENU_API },
  } = state;

  if (
    currentMenuVersion === 'latest' &&
    currentStage === MenuStages.PLAYGROUND
  ) {
    const client = getGraphQLClient(PRP_API);
    const categoryAndTimePeriodJSON = (await client.categoryAndTimePeriodQuery({
      restaurantCode,
      version: null,
    })) as any as CategoryAndTimePeriodQueryQuery;

    const persistentMenuProperty = await getPersistentMenuPropByRestaurant(
      PRP_PERSISTENT_API,
      restaurantCode,
      {
        property_key: VOICE_PROPERTIES,
      }
    );

    const menuJSON = (await client.menuItemsQuery({
      restaurantCode,
      version: null,
    })) as any as MenuItemsQueryQuery;

    const modifierGroupJSON = (await client.modifierGroupsQuery({
      restaurantCode,
      version: null,
    })) as any as ModifierGroupsQueryQuery;

    return {
      categoryAndTimePeriodJSON,
      menuJSON,
      modifierGroupJSON,
      persistentMenuProperty,
      unavailableItems,
    };
  } else {
    const params: { [key: string]: string } = {
      restaurant_code: restaurantCode,
    };
    if (currentMenuVersion) {
      params['commit_id'] = currentMenuVersion;
    }

    logger.info({
      restaurantCode,
      message:
        'Function fetchMenuBasedOnStage: Before calling getMenuURLFromMenuAPI',
    });
    const {
      data: { menu_url, unavailable_items, commit_id: activeCommitId },
    }: {
      data: {
        menu_url: string;
        unavailable_items: IFetchUnavailableItem[];
        commit_id: string;
      };
    } = await getMenuURLFromMenuAPI(MENU_API, currentStage, params);
    logger.info({
      restaurantCode,
      message:
        'Function fetchMenuBasedOnStage: After response from getMenuURLFromMenuAPI',
    });

    if (menu_url) {
      unavailableItems = formatUnavailableItems(unavailable_items);

      const { cachedRestaurantIndex, storedMenuDetails } = isMenuInCache({
        restaurantCode,
        activeCommitId,
        cachedMenu,
      });

      if (Object.keys(storedMenuDetails.menuJSON).length) {
        return { ...storedMenuDetails, unavailableItems };
      } else {
        logger.info({
          restaurantCode,
          message:
            'Function fetchMenuBasedOnStage: Before calling getMenuFromMenuAPI',
        });

        const {
          categories,
          timePeriods,
          menuItemSettings,
          menuItems,
          menuOverrides,
          posProperties,
          modifierGroups,
          voiceProperties,
        }: any = await getMenuFromMenuAPI(menu_url, {});

        logger.info({
          restaurantCode,
          message:
            'Function fetchMenuBasedOnStage: After response from getMenuFromMenuAPI',
        });

        const outputResponse = {
          categoryAndTimePeriodJSON: {
            categories,
            timePeriods,
          },
          menuJSON: {
            menuItemSettings,
            menuItems,
            menuOverrides,
            posProperties,
          },
          modifierGroupJSON: {
            modifierGroups,
          },
          persistentMenuProperty: voiceProperties,
          unavailableItems,
        };

        if (cachedRestaurantIndex > -1) {
          //For a specific restaurant, if the active version is different from the one which is already cached
          dispatch(
            updateMenuData({
              restaurantId: restaurantCode,
              menuVersion: activeCommitId,
              menuData: outputResponse,
              index: cachedRestaurantIndex,
            })
          );
        } else {
          //Cache the menu data for quick access to menu data in the future
          dispatch(
            addMenuData({
              restaurantId: restaurantCode,
              menuVersion: activeCommitId,
              menuData: outputResponse,
            })
          );
        }
        return outputResponse;
      }
    }

    return {
      menuJSON: {},
      modifierGroupJSON: {},
      categoryAndTimePeriodJSON: {},
      persistentMenuProperty: {},
      unavailableItems,
    };
  }
}

export const generateMenuTitle = (commitId: string, comment?: string) =>
  comment ? `${commitId} - ${comment}` : commitId;

export const getUnAvailableUntilAndAvailable = ({
  item,
  stage,
  unavailableItems,
}: {
  item: MenuItem;
  stage: string;
  unavailableItems: Record<string, IUnavailableItem>;
}) => {
  let { unavailableUntil, name = '', available } = item || {};
  if (stage.toUpperCase() === MenuStages.LIVE && unavailableItems[name]) {
    unavailableUntil = unavailableItems[name].unavailableUntil;
    available = false;
  }
  return { unavailableUntil, available };
};

export const getComboPrpIdToALaCartePrpId = (
  autoComboPrpIds: string[],
  menuRes: MenuResponses
) => {
  const autoComboModifierPrpIds: string[] = [];
  if (autoComboPrpIds) {
    autoComboPrpIds.forEach((autoComboPrpId) => {
      let autoCombo = menuRes.menuItems[autoComboPrpId];
      const posProperties = getPosProperties(autoCombo, menuRes);
      const isDummyItem =
        Object.values(posProperties).find((posProp) => posProp.key === 'pos_id')
          ?.value === DUMMY_ITEM;
      if (isDummyItem && autoCombo.modifierGroups.length) {
        menuRes.modifierGroups[autoCombo.modifierGroups[0]].menuItems.forEach(
          (id) => {
            menuRes.menuItems[id].modifierGroups.forEach((modifierGroupId) => {
              autoComboModifierPrpIds.push(
                ...menuRes.modifierGroups[modifierGroupId]?.menuItems.map(
                  (id) => String(id)
                )
              );
            });
          }
        );
      } else {
        autoCombo.modifierGroups.forEach((modifierGroupId) => {
          autoComboModifierPrpIds.push(
            ...menuRes.modifierGroups[modifierGroupId]?.menuItems.map((id) =>
              String(id)
            )
          );
        });
      }
    });
  }

  const posIdOrNameToPrpId: { [key: string]: string } = {};
  Object.values(menuRes.menuItems).forEach((menuItem) => {
    const posProperties = getPosProperties(menuItem, menuRes);
    const posId = Object.values(posProperties).find(
      (posProp) => posProp.key === 'pos_id'
    )?.value;
    const posName = Object.values(posProperties).find(
      (posProp) => posProp.key === 'pos_name'
    )?.value;
    if (posId) posIdOrNameToPrpId[posId] = menuItem.id;
    if (posName) posIdOrNameToPrpId[posName] = menuItem.id;
  });

  const aLaCartePosIdToPrpId: { [key: string]: string } = {};
  const comboPrpIdToALaCartePrpId: { [key: string]: string } = {};

  autoComboModifierPrpIds.forEach((comboPrpId) => {
    const posProperties = getPosProperties(
      menuRes.menuItems[comboPrpId],
      menuRes
    );
    const aLaCartePosId = Object.values(posProperties).find(
      (posProp) => posProp.key === 'a_la_carte_pos_id'
    )?.value;
    if (aLaCartePosId) {
      aLaCartePosIdToPrpId[aLaCartePosId] = comboPrpId;
      if (aLaCartePosId in posIdOrNameToPrpId)
        comboPrpIdToALaCartePrpId[comboPrpId] =
          posIdOrNameToPrpId[aLaCartePosId];
    } else {
      comboPrpIdToALaCartePrpId[comboPrpId] = comboPrpId;
    }
  });

  return comboPrpIdToALaCartePrpId;
};

export const findCartItemInMenuGraphByName = (
  cartItem: CartItem,
  menuItem: ParsedMenuItem
) => {
  let menuItemMatchByNameId = '';
  let modGroupMatchByNameId = '';
  Object.values(menuItem.modifierGroups).forEach((modGroup) => {
    Object.values(modGroup.menuItems).forEach((menuItem) => {
      if (menuItem.name === cartItem.name) {
        menuItemMatchByNameId = menuItem.id;
        modGroupMatchByNameId = modGroup.id;
      }
    });
  });
  return { menuItemMatchByNameId, modGroupMatchByNameId };
};

export const getEligibleAutoComboItem = (
  autoCombo: MenuItem,
  menuRes: MenuResponses,
  autoComboPrpId: string,
  cartItemsQuantity: Record<string, number>,
  cartItems: Record<string, CartItem>
) => {
  const comboItemMatches: IComboItemMatch[] = [];
  let isAutoComboEligible = false;
  const posProperties = getPosProperties(autoCombo, menuRes);
  const isDummyItem =
    Object.values(posProperties).find((posProp) => posProp.key === 'pos_id')
      ?.value === DUMMY_ITEM;
  let nonDummyItemMatch;
  try {
    if (isDummyItem) {
      //find the non-dummy combo items
      const realComboItems =
        menuRes.modifierGroups[autoCombo.modifierGroups[0]].menuItems;
      for (let i = 0; i < realComboItems.length; i++) {
        const cartCopy = Object.assign({}, cartItems);
        const cartItemsQuantityCopy = Object.assign({}, cartItemsQuantity);
        const nonDummyItem = menuRes.menuItems[realComboItems[i]];
        isAutoComboEligible = nonDummyItem.modifierGroups.every(
          (modifierGroupId) => {
            const modifiersPrpIds = menuRes.modifierGroups[
              modifierGroupId
            ].menuItems.map((id) => String(id));

            return modifiersPrpIds.find((modifierPrpId) => {
              const modifier = menuRes.menuItems[modifierPrpId];
              const modifierPosProperties = getPosProperties(modifier, menuRes);

              const match = getCartItemMatchByPosProperty(
                modifierPosProperties,
                cartCopy
              );
              if (match) {
                const cartItemId = match.cartItemId;
                comboItemMatches.push({
                  autoComboPrpId,
                  nonDummyItemPrpId: nonDummyItem.id,
                  modifierGroupId: String(modifierGroupId),
                  modifierPrpId,
                  cartItemId,
                });
                if (cartItemsQuantityCopy[cartItemId] > 1) {
                  cartItemsQuantityCopy[cartItemId] -= 1;
                } else {
                  delete cartCopy[cartItemId];
                  delete cartItemsQuantityCopy[cartItemId];
                }
              }
              return match;
            });
          }
        );

        if (isAutoComboEligible) {
          nonDummyItemMatch = nonDummyItem;
          break;
        } else {
          comboItemMatches.length = 0; // Reset if no eligible auto combo found
        }
      }
    } else {
      isAutoComboEligible = autoCombo.modifierGroups.every(
        (modifierGroupId) => {
          const modifiersPrpIds = menuRes.modifierGroups[
            modifierGroupId
          ].menuItems.map((id) => String(id));
          return modifiersPrpIds.find((modifierPrpId) => {
            // find cart item match
            const modifier = menuRes.menuItems[modifierPrpId];
            const modifierPosProperties = getPosProperties(modifier, menuRes);

            const match = getCartItemMatchByPosProperty(
              modifierPosProperties,
              cartItems
            );
            if (match) {
              const cartItemId = match.cartItemId;
              comboItemMatches.push({
                autoComboPrpId,
                modifierGroupId: String(modifierGroupId),
                modifierPrpId,
                cartItemId,
              });
              if (cartItemsQuantity[cartItemId] > 1) {
                cartItemsQuantity[cartItemId] -= 1;
              } else {
                delete cartItems[cartItemId];
                delete cartItemsQuantity[cartItemId];
              }
            }
            return match;
          });
        }
      );
    }
  } catch (error) {
    logger.error({
      message: 'Get eligible Auto Combo Failed',
      error: JSON.stringify(error),
    });
  }
  return {
    isAutoComboEligible,
    comboItemMatches,
    isDummyItem,
    nonDummyItemMatch,
  };
};

const isALaCarteItemMatch = (
  menuItemPosProperties: Record<string, PosProperties>,
  cartItemPosProperties: Record<string, PosProperties>
) => {
  const menuItemPosId =
    getPosPropertyValue(menuItemPosProperties, A_LA_CARTE_POS_ID) ||
    getPosPropertyValue(menuItemPosProperties, POS_ID);
  const menuItemPosName =
    getPosPropertyValue(menuItemPosProperties, A_LA_CARTE_POS_ID) ||
    getPosPropertyValue(menuItemPosProperties, POS_NAME);

  const cartItemPosId = getPosPropertyValue(cartItemPosProperties, POS_ID);
  const cartItemPosName = getPosPropertyValue(cartItemPosProperties, POS_NAME);

  if (
    menuItemPosId &&
    menuItemPosId !== DUMMY_ITEM &&
    menuItemPosId === cartItemPosId
  )
    return true;
  return !!(menuItemPosName && menuItemPosName === cartItemPosName);
};

const getCartItemMatchByPosProperty = (
  menuItemPosProperties: Record<string, PosProperties>,
  cartItems: Record<string, CartItem>
) => {
  return Object.values(cartItems).find((cartItem) => {
    const node = getCartItemNonDummyNode(cartItem);

    return isALaCarteItemMatch(menuItemPosProperties, node.posProperties);
  });
};
