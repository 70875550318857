import { useAlert } from 'react-alert';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ORDER_STATUS_ICON_RESET_INTERVAL } from '../../constants';
import { resetMenu } from '../../reducers/menuSlice';
import {
  AgentInterceptionType,
  EndSessionTransmissionMessage,
  InfoTransmissionMessage,
  TextFrameTransmissionMessage,
  messagingActions,
} from '../../reducers/messagingSlice';
import { selectTRAgentAutoAssignment } from '../../redux/features/config/config.selector';
import { SessionStartReasons } from '../../redux/features/sessionBoundary/sessionBoundary.constants';
import { setSessionStart } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import {
  selectCurrentTask,
  selectTasksById,
} from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import logger from '../../utils/logger';
import useAgentInterception from '../agentInterception/useAgentInterception.hooks';
import useTaskRouterHandlers from './taskRouterHandlers.hooks';

const useTaskRouterMenuHandlers = () => {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const currentTaskId = useAppSelector(selectCurrentTask);
  const tasksById = useAppSelector(selectTasksById);
  const { sendAgentInterception } = useAgentInterception();
  const { navigateToTaskRouterPage } = useTaskRouterHandlers();
  const isTaskAssignmentMode = useAppSelector(selectTRAgentAutoAssignment);

  const taskRouterMenuLoadFailureCallback = ({
    restaurantCode,
    orderSessionId,
    messageSessionId,
  }: {
    restaurantCode: string;
    orderSessionId: string;
    messageSessionId: string;
  }) => {
    const message = 'failed to load menu';

    dispatch(resetMenu());
    alert.error(message, { timeout: ORDER_STATUS_ICON_RESET_INTERVAL });

    const endSessionPayload: Partial<EndSessionTransmissionMessage> = {
      data: {
        restaurant_code: restaurantCode,
        session_id: orderSessionId || messageSessionId,
      },
      metadata: message,
    };
    dispatch(
      messagingActions.sendEndSession(
        endSessionPayload as EndSessionTransmissionMessage
      )
    );

    const messagePayload: Partial<TextFrameTransmissionMessage> = {
      data: {
        payload: 'One moment, let me get a team member to help you',
        status: 'final',
        metadata: { intent: '#failedMenuLoad', timeDelta: 1 },
      },
    };
    const infoPayload: Partial<InfoTransmissionMessage> = {
      data: {
        message,
        type: 'METRIC',
        metadata: { event_name: 'intervention_reason', reason: message },
      },
    };
    dispatch(
      messagingActions.sendMessage(
        messagePayload as TextFrameTransmissionMessage
      )
    );
    dispatch(messagingActions.sendInfo(infoPayload as InfoTransmissionMessage));
    sendAgentInterception({
      data: {
        type: AgentInterceptionType.TTS_SENT,
      },
    });

    setTimeout(() => {
      logger.info({
        message:
          'In taskRouterMenuLoadFailureCallback: Before updating task status',
        taskId: currentTaskId,
        isTR: true,
      });
      dispatch(
        updateTaskStatus({
          taskId: currentTaskId,
          event: isTaskAssignmentMode
            ? TaskStatuses.loadFail
            : TaskStatuses.canceled,
        })
      );
      logger.log('Call navigateToTaskRouterPage after loading menu failed');
      navigateToTaskRouterPage();
    }, ORDER_STATUS_ICON_RESET_INTERVAL);
  };

  const taskRouterMenuLoadSuccessCallback = () => {
    logger.info({
      message:
        'In taskRouterMenuLoadSuccessCallback: Updating taskId with status as task_loaded',
      taskId: currentTaskId,
      isTR: true,
    });
    dispatch(
      updateTaskStatus({
        taskId: currentTaskId,
        event: TaskStatuses.loaded,
      })
    );
    // Starts HITL session by accepting a task
    const { session_id: sessionId } = tasksById[currentTaskId];
    logger.log(
      'Set HITL session start reason as task_assigned in taskRouterMenuLoadSuccessCallback'
    );
    dispatch(
      setSessionStart({
        sessionId,
        sessionStartReason: SessionStartReasons.taskAssigned,
      })
    );
  };

  return {
    taskRouterMenuLoadFailureCallback,
    taskRouterMenuLoadSuccessCallback,
  };
};

export default useTaskRouterMenuHandlers;
