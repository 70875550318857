import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  TextField,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { ArrowKeys, Symbols } from '../../constants/enums';
import useOmniBarKeyboardActions from '../../hooks/useOmniBarKeyboardActions.hooks';
import {
  addCouponToCart,
  applyLoyaltyCode,
  cartActions,
} from '../../reducers/cartSlice';
import { dialogActions } from '../../reducers/dialogSlice';
import { addItemToCart } from '../../reducers/menuSlice';
import {
  AgentInterceptionType,
  InfoTransmissionMessage,
  messagingActions,
} from '../../reducers/messagingSlice';
import { selectDisableIntentInterval } from '../../redux/features/config/config.selector';
import {
  invalidModGroupsSelector,
  selectCartItems,
  selectCartItemsQuantity,
} from '../../selectors/cart';
import { selectDialog } from '../../selectors/dialog';
import {
  generateMenuItemsSelector,
  getModSymbolMapping,
  posSettingsSelector,
} from '../../selectors/menu';
import { selectActiveRestaurantDetails } from '../../selectors/restaurant';
import { getItemUnavailableMessage } from '../../utils/TTSMessage';
import {
  findAnotherSelectedItem,
  getFilteredOptions,
  getOptionLabel,
  getQuantityFromInput,
  iterateModGroups,
  processQuantity,
} from '../../utils/autocomplete';
import {
  getCartInvalidModGroupDescriptions,
  getCartItemProperties,
} from '../../utils/cart';
import Colors from '../../utils/color';
import {
  INTENT_CARDS,
  TTS_OFF_COMMAND,
  TTS_ON_COMMAND,
} from '../../utils/constants';
import {
  ParsedMenuItem,
  TopLevelMenuItem,
  convertToMap,
  isItem86edToday,
  sortModGroups,
} from '../../utils/menu';
import { AutocompleteOption } from './AutocompleteOption';
import useAutocompleteMessage from './hooks/useAutocompleteMessage';
import { checkEnterClicked } from './omnibar.constant';
import useSendMessageHooks from "../../hooks/useSendMessage/useSendMessage.hooks";

const useStyles = makeStyles((theme: Theme) => ({
  wrapText: {
    width: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: Colors.white,
    padding: theme.spacing(1),
    '& input': {
      paddingLeft: `${theme.spacing(1)} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  option: {
    pointerEvents: 'none',
  },
}));

interface IAutocompleteComp {
  disabled: boolean;
}

export const AutocompleteComp = ({ disabled }: IAutocompleteComp) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    currentMessage,
    updateCurrentMessage,
    sendMessage,
    sendAgentInterventionEvent,
  } = useAutocompleteMessage();
  const menuItems = useSelector(generateMenuItemsSelector(currentMessage));

  const cartSequenceId = useSelector(
    (state: RootState) => state.cart.sequenceId
  );
  const cart = useShallowSelector((state: RootState) => state.cart);
  const selectedItem =
    useAppSelector((state) => state.dialog.selectedItem) ||
    Object.values(cart.cartItems)[Object.values(cart.cartItems).length - 1];

  const selectedItemCartId =
    useAppSelector((state) => state.dialog.selectedItemCartId) ||
    parseInt(
      Object.keys(cart.cartItems).find(
        (key) => cart.cartItems[key] === selectedItem
      ) || '0'
    );

  const dialogState = useAppSelector(selectDialog);
  const {
    steps: dialogSteps,
    step: dialogStep,
    voiceBoardValue,
    intentsStatus,
    nextSelectedModGroupIndex,
    selectedModGroup,
  } = dialogState;
  const { restaurantName } = useSelector(
    selectActiveRestaurantDetails
  );
  const posSettings = useSelector(posSettingsSelector);

  const [inputQuantity, setInputQuantity] = useState(1);
  const [inputModSymbol, setInputModSymbol] = useState('');
  const fullMenuItems = useAppSelector((state) => state.menu.fullMenuItems);
  const [downTime, setDownTime] = useState(0);
  const [hasPreviousMessage, setHasPreviousMessage] = useState(false);
  const modSymbolMapping = useShallowSelector(getModSymbolMapping);

  // Loyalty Code
  const [loyaltyCode, setLoyaltyCode] = useState<string | null>(null);

  // Coupon Code
  const [couponCode, setCouponCode] = useState<string | null>(null);

  const [prefixWord, setPrefixWord] = useState('');
  const [handleOutOfOrderSearch, setHandleOutOfOrderSearch] = useState(false);
  const { arrowDown, arrowLeft, arrowRight, arrowUp } = ArrowKeys;
  const { processCartItemSelectionKey } = useOmniBarKeyboardActions();
  const { sendReadBackMessage } = useSendMessageHooks()

  useEffect(() => {
    if (currentMessage) {
      setHasPreviousMessage(true);
    } else {
      let timer = setTimeout(() => {
        setHasPreviousMessage(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentMessage]);

  // Get invalid modifier group description for agent to send as autoprompter if there is any
  const invalidModGroups = useSelector(invalidModGroupsSelector);
  const invalidModGroupDescriptions =
    getCartInvalidModGroupDescriptions(invalidModGroups);
  if (invalidModGroupDescriptions.length)
    dispatch(
      dialogActions.addInvalidModGroupDescriptions(invalidModGroupDescriptions)
    );

  const getCurrentDialogStep = () => {
    if (selectedItem) {
      if (invalidModGroupDescriptions.length) {
        return invalidModGroupDescriptions[0];
      } else if (dialogStep !== 1) {
        dispatch(dialogActions.setStep(1));
      }
    }

    return dialogSteps[dialogStep].replaceAll(
      '{RESTAURANT_NAME}',
      restaurantName
    );
  };

  const DISABLE_INTENT_INTERVAL = useAppSelector(selectDisableIntentInterval);

  const handleKeyPress = (event: KeyboardEvent) => {
    const payload: Partial<InfoTransmissionMessage> = {
      data: { message: 'Key ' + event.key + ' pressed', type: 'METRIC' },
    };
    dispatch(messagingActions.sendInfo(payload as any));
    if (event.key === 'Enter') {
      event.preventDefault();
      !checkEnterClicked.getIsEnterClicked() && handleEnterPress(event);
    }
  };

  const handleEnterPress = (event: KeyboardEvent) => {
    if (loyaltyCode) {
      // Call Apply Loyalty Code API
      dispatch(applyLoyaltyCode({ loyaltyCode }));
    } else if (couponCode) {
      dispatch(addCouponToCart(couponCode.toString()));
      setCouponCode(null); // clear coupon state after adding to cart
    } else if (!event.shiftKey) {
      if (voiceBoardValue !== '' && voiceBoardValue[0] === '/') {
        handleMessageSubmit();
      } else if (!event.shiftKey) {
        sendMessage(getCurrentDialogStep());
        if (dialogStep === dialogSteps.length - 1) {
          if (dialogStep !== 0) {
            dispatch(dialogActions.setStep(0));
          }
        } else {
          dispatch(dialogActions.increaseStep());
        }
      }
    }
    checkEnterClicked.setIsEnterClicked(true);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      handleTabKey(event);
    } else if (
      [arrowDown, arrowLeft, arrowRight, arrowUp, ' '].includes(event.key)
    ) {
      if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.key !== ' ' && !downTime) setDownTime(new Date().valueOf()); // Arrow keys use key press duration to select the TTS message
    }
  };

  const sendTTSByUsingArrowKeys = (event: KeyboardEvent) => {
    let upTime = new Date().valueOf();
    const timePressed = Math.floor((upTime - downTime) / 100);
    const getMessage = (card: number) =>
      INTENT_CARDS[card].messages[timePressed] ||
      INTENT_CARDS[card].messages[INTENT_CARDS[card].messages.length - 1];

    let intentIndex: number | null = null;

    switch (event.key) {
      case arrowUp:
        intentIndex = 1; // #okay
        break;
      case arrowLeft:
        intentIndex = 3; // #moment
        break;
      case arrowRight:
        intentIndex = 5; // #continue
        break;
      case arrowDown:
        intentIndex = 6; // #readback
        break;
      default:
        break;
    }

    if (intentIndex && intentsStatus[intentIndex]) {
      if (intentIndex === 6) {
        sendReadBackMessage(INTENT_CARDS[6].intent, timePressed);
        sendAgentInterventionEvent(AgentInterceptionType.TTS_SENT);
      } else {
        sendMessage(getMessage(intentIndex));
      }
      dispatch(
        dialogActions.setIntentsStatus({ idx: intentIndex, status: false })
      );
      setTimeout(() => {
        if (intentIndex)
          dispatch(
            dialogActions.setIntentsStatus({ idx: intentIndex, status: true })
          );
      }, DISABLE_INTENT_INTERVAL);
    }

    setDownTime(0);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!currentMessage) {
      event.preventDefault();
      if (
        Object.values(ArrowKeys).includes(event.key as ArrowKeys) &&
        !event.shiftKey
      )
        sendTTSByUsingArrowKeys(event); //click arrow keys when there is nothing typed on Omnibar
    }

    if (event.shiftKey) {
      event.preventDefault();
      // Use Shift+Backspace key for removing last cart item
      if (!currentMessage && !hasPreviousMessage) {
        if (
          event.key === 'Backspace' &&
          selectedItem &&
          selectedItemCartId > 0
        ) {
          dispatch(cartActions.deleteCartItem(selectedItemCartId.toString()));
          sendAgentInterventionEvent(
            AgentInterceptionType.ORDER_CHANGED,
            `${selectedItem.name} removed from order`
          );

          const newCartItemId = findAnotherSelectedItem(
            cart.cartItems,
            selectedItemCartId
          );
          if (newCartItemId > 0) {
            dispatch(
              dialogActions.updateSelectedItem({
                item: cart.cartItems[newCartItemId.toString()],
                itemCartId: newCartItemId,
              })
            );
          } else {
            dispatch(dialogActions.updateSelectedItem());
          }
          dispatch(dialogActions.setModGroupTabIndex(0));
          dispatch(dialogActions.updateSelectedModGroup());
        }
        if (
          [ArrowKeys.arrowDown, ArrowKeys.arrowUp].includes(event.key as any)
        ) {
          processCartItemSelectionKey(event.key);
        }
      }
    }
    checkEnterClicked.setIsEnterClicked(false);
  };

  const handleTabKey = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const sortedSelectedModGroups = sortModGroups(selectedItem);

    if (sortedSelectedModGroups.length) {
      const currentSelectedModGroupIndex =
        nextSelectedModGroupIndex >= sortedSelectedModGroups.length
          ? 0
          : nextSelectedModGroupIndex;
      dispatch(
        dialogActions.updateSelectedModGroup(
          sortedSelectedModGroups[currentSelectedModGroupIndex]
        )
      );
      dispatch(
        dialogActions.setModGroupTabIndex(currentSelectedModGroupIndex + 1)
      );
    }
  };

  const handleMessageSubmit = () => {
    if (voiceBoardValue.length > 0) {
      //Send message only when the agent typed '//ttson' for TTS-ON and '/\ttsoff' for TTS-OFF
      if (voiceBoardValue === TTS_OFF_COMMAND) {
        sendMessage('\\'); // The backslash ("\") character is a special escape character. To use backslash character itself, two backslashes are needed.
      }

      if (voiceBoardValue === TTS_ON_COMMAND) {
        sendMessage('/');
      }

      updateCurrentMessage('');
      dispatch(dialogActions.setVoiceBoardValue(''));
    }
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const curValue: any = event.currentTarget.value;

    // Agent Interception TYPING event
    if (!currentMessage && curValue) {
      sendAgentInterventionEvent(AgentInterceptionType.TYPING);
    }

    if (
      curValue &&
      [Symbols.star, Symbols.plus, Symbols.minus, Symbols.caret].includes(
        curValue[0]
      )
    ) {
      const firstChar = curValue[0];
      if ([Symbols.star, Symbols.caret].includes(firstChar)) {
        //Logic related to coupon code
        const couponNum = curValue.slice(1);
        if (!isNaN(couponNum)) {
          if (firstChar === Symbols.star && couponNum.length <= 6) {
            // loyalty coupon code should not be longer than 6 digits
            setLoyaltyCode(couponNum);
            if (couponCode) setCouponCode(null);
            updateCurrentMessage(Symbols.star + couponNum);
          } else if (firstChar === Symbols.caret) {
            // use caret key: ^ to enter coupon besides loyalty
            setCouponCode(couponNum);
            if (loyaltyCode) setLoyaltyCode(null);
            updateCurrentMessage(Symbols.caret + couponNum);
          }
        }
      } else if ([Symbols.plus, Symbols.minus].includes(firstChar)) {
        updateCurrentMessage(curValue);
      }
    } else {
      updateCurrentMessage(curValue);
      setLoyaltyCode(null);
      setCouponCode(null);
      dispatch(dialogActions.setVoiceBoardValue(curValue));
    }
  };

  const setCurrentMessageAndVoiceBoardValue = (
    message = '',
    voiceBoardValue = ''
  ) => {
    updateCurrentMessage(message);
    dispatch(dialogActions.setVoiceBoardValue(voiceBoardValue));
  };

  const onSelectChange = (
    event: any,
    value: ParsedMenuItem | string | TopLevelMenuItem | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value === null) {
      setCurrentMessageAndVoiceBoardValue();
    } else if (typeof value === 'string') {
      setCurrentMessageAndVoiceBoardValue(value, value);
    } else if (value instanceof Object) {
      if (isItem86edToday(value)) {
        sendMessage(getItemUnavailableMessage(value.name));
        return;
      }
      if (
        !value.name.includes('/upsell') &&
        (value.category === 'tts-prompt' || value.category.startsWith('/'))
      ) {
        let [intent, sendVal] = value.name.replace('/', '').split(':');
        sendMessage(sendVal, { intent });
        setCurrentMessageAndVoiceBoardValue();
      } else if (value.category === 'modifier') {
        setCurrentMessageAndVoiceBoardValue();
        if (selectedItem && selectedItemCartId) {
          const menuItem = value as ParsedMenuItem;

          // Declined removes selected upsell item
          if (menuItem.name.toLocaleLowerCase() === 'declined') {
            dispatch(cartActions.deleteCartItem(selectedItemCartId.toString()));
            sendAgentInterventionEvent(
              AgentInterceptionType.ORDER_CHANGED,
              `${selectedItem.name} removed from order`
            );

            const newCartItemId = findAnotherSelectedItem(
              cart.cartItems,
              selectedItemCartId
            );
            if (newCartItemId > 0) {
              dispatch(
                dialogActions.updateSelectedItem({
                  item: cart.cartItems[newCartItemId.toString()],
                  itemCartId: newCartItemId,
                })
              );
            } else {
              dispatch(dialogActions.updateSelectedItem());
            }
            dispatch(dialogActions.setModGroupTabIndex(0));
            dispatch(dialogActions.updateSelectedModGroup());
          }

          let groupsToCheck = selectedModGroup
            ? [selectedModGroup]
            : Object.values(selectedItem.modifierGroups);

          let childrenMods = selectedModGroup
            ? convertToMap(
                Object.values(
                  cart.cartItems[selectedItemCartId]?.childModifierGroups
                ).filter(
                  (childModGroup) => childModGroup.id === selectedModGroup.id
                )
              )
            : cart.cartItems[selectedItemCartId]?.childModifierGroups;
          let isChecked = iterateModGroups(
            childrenMods,
            groupsToCheck,
            menuItem,
            true,
            inputModSymbol
          );

          let modGroup = groupsToCheck.filter(
            (mg) =>
              Object.values(mg.menuItems).filter(
                (modifier) =>
                  modifier.id === menuItem.id &&
                  menuItem.parentModifierGroupId === mg.id
              ).length
          );

          if (modGroup.length > 0) {
            let mg = modGroup[0];

            dispatch(
              cartActions.selectModifier({
                cartItemId: selectedItemCartId,
                menuItem,
                modGroup: mg,
                selected: isChecked,
                modCode: inputModSymbol,
                modSymbolMapping,
                rootModGroupId: selectedModGroup?.id,
                posSettings,
              })
            );
          }
        }
      } else {
        if (value.name.includes('/upsell')) {
          let [intent, sendVal] = value.name.replace('/', '').split(':');
          sendMessage(sendVal, { intent });
          updateCurrentMessage('');
        }
        dispatch(dialogActions.setVoiceBoardValue(''));

        // note: mod symbol never used on root items
        dispatch(
          addItemToCart({ menuItem: value, prefixWord, inputModSymbol })
        );
        sendAgentInterventionEvent(
          AgentInterceptionType.ORDER_CHANGED,
          `${value.name} added to cart`
        );

        const { id, categoryId } = value;
        const itemId = `${id}-${categoryId}`;

        dispatch(
          dialogActions.updateSelectedItem({
            item: fullMenuItems[itemId],
            itemCartId: cartSequenceId,
          })
        );

        dispatch(dialogActions.setModGroupTabIndex(0));
        dispatch(dialogActions.updateSelectedModGroup());

        const quantity = processQuantity(inputQuantity, value, currentMessage);
        dispatch(
          cartActions.updateQuantity({
            cartItemId: cartSequenceId.toString(),
            quantity: Math.min(quantity, 19),
          })
        );
        setInputQuantity(1);
        updateCurrentMessage('');
        setPrefixWord('');
        setHandleOutOfOrderSearch(false);
      }
    }
  };

  const getPlaceHolder = () =>
    invalidModGroupDescriptions.length > 0
      ? invalidModGroupDescriptions[0]
      : getCurrentDialogStep();

  const filterOptions = (
    options: (TopLevelMenuItem | ParsedMenuItem)[],
    state: FilterOptionsState<TopLevelMenuItem | ParsedMenuItem>
  ) => {
    const { quantity, inputValue, modSymbol } = getQuantityFromInput(
      state.inputValue,
      modSymbolMapping
    );

    setInputQuantity(quantity || 1);
    setInputModSymbol(modSymbol ? modSymbol : '');

    let resultList = getFilteredOptions(
      modSymbol,
      inputValue,
      quantity,
      options,
      state.inputValue
    );

    // search for the second word if no result found for the whole input value and only hanlde two words for now. i.e. coke large
    const splittedInputValue = inputValue.trim().split(' ');
    if (!Object.values(resultList).length && splittedInputValue.length === 2) {
      resultList = getFilteredOptions(
        modSymbol,
        splittedInputValue[1],
        quantity,
        options,
        state.inputValue
      );
      setPrefixWord(splittedInputValue[0]);
      setHandleOutOfOrderSearch(true);
    }

    return resultList;
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    menuItem: ParsedMenuItem | TopLevelMenuItem,
    state: AutocompleteRenderOptionState
  ) => {
    const optionProps = {
      htmlElemProps: props,
      menuItem,
      state,
      handleOutOfOrderSearch,
    };
    return (
      <AutocompleteOption
        key={`${menuItem.id}${
          menuItem.categoryId ||
          (menuItem as ParsedMenuItem)?.parentModifierGroupId
        }`}
        {...optionProps}
      />
    );
  };

  return (
    <Autocomplete
      data-testid="omnibar-autocomplete"
      freeSolo
      fullWidth
      clearOnEscape
      open={currentMessage !== ''}
      autoHighlight={true}
      value={null}
      inputValue={currentMessage}
      onChange={onSelectChange}
      options={menuItems}
      getOptionLabel={getOptionLabel}
      onClose={() => updateCurrentMessage('')}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={(input) => input && input.focus()}
          className={classes.wrapText}
          autoFocus
          id="omnitext"
          placeholder={getPlaceHolder()}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          value={currentMessage}
          onChange={onTextChange}
          disabled={disabled}
          InputProps={{ ...params.InputProps, endAdornment: null }}
        />
      )}
      filterOptions={filterOptions}
      renderOption={renderOption}
    />
  );
};
